/** @format */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Feature = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className='content'>
			<div className='container'>
				<div className='card'>
					<div className='card-body'>
						<h1 className='text-center py-3'>
							Features of Vyapar Sahayak
						</h1>
						<ul>
							<li>
								<i className='far fa-circle-right'></i>&nbsp;
								<strong>Completely paperless</strong>&nbsp;
								<i className='fa fa-arrow-right'></i>
								No more tiring paper work. Completely digital.
								Concentrate on growing your business
							</li>
							<li>
								<i className='far fa-circle-right'></i>&nbsp;
								<strong>Private & Secure</strong>&nbsp;
								<i className='fa fa-arrow-right'></i>
								All the data is stored on cloud server. Hence
								there is no more fear of loosing the
								transaction.
							</li>
							<li>
								<i className='far fa-circle-right'></i>&nbsp;
								<strong>Inventory Management</strong>&nbsp;
								<i className='fa fa-arrow-right'></i>
								Simply add purchase and sale records. The
								software will track the real time inventory for
								you.
							</li>
							<li>
								<i className='far fa-circle-right'></i>&nbsp;
								<strong>Easy Reporting</strong>&nbsp;
								<i className='fa fa-arrow-right'></i> Generate
								easy Reports for taxation.
							</li>
							<li>
								<i className='far fa-circle-right'></i>&nbsp;
								<strong>Download Reports</strong>&nbsp;
								<i className='fa fa-arrow-right'></i> Data
								analysis plays important role in identifying the
								weak spots. With the software you can download
								historical data with a click and make plan for
								business expansion.
							</li>
						</ul>

						<p>
							+ <strong>Vyapar Sahayak</strong> offers many more
							features for your customized business needs.
						</p>
						<h2 className='text-center py-4'>
							<Link to='/'> Try Vyapar Sahayak today</Link>
						</h2>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Feature;
