import React from "react";
import { useParams } from "react-router-dom";

const Pricing = () => {
	const { slug } = useParams();
	return (
		<div className='container'>
			<h1>Pricing</h1>
			<p>slug : {slug}</p>
		</div>
	);
};

export default Pricing;
