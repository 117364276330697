/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuthCheck from "../../../Hooks/useAuthCheck";
// import useForm from "../../../Hooks/useForm";
import NoValidation from "./Form/NoValidation";

import { useForm } from "react-hook-form";

const apiRoot = process.env.REACT_APP_API_ROOT;
const EditCompany = () => {
	let navigate = useNavigate();

	const params = useParams();
	const id = params.id;

	// Auth check
	const authError = useAuthCheck(5);
	useEffect(() => {
		if (authError) {
			navigate("/", {
				state: authError.errMessage,
			});
		} else {
			loadPage();
		}
	}, []);

	const callbackForm = () => {
		submitForm();
	};

	// const { values, errors, handleChange, handleSubmit } = useForm(
	// 	callbackForm,
	// 	NoValidation
	// );
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();
	// const onSubmit = (data) => console.log(data);

	const submitForm = async (data) => {
		const submitUrl = apiRoot + `/v1/admin/company/${id}`;
		try {
			const res = await fetch(submitUrl, {
				method: "PUT",
				headers: {
					// "Content-Type": "application/json",
					// "Content-Type": "multipart/form-data",
					token: localStorage.getItem("token"),
				},
				body: JSON.stringify(data),
			});

			if (res.status === 200) {
				const data = await res.json();
				console.log(data);
				navigate("/admin/company", {
					state: data.message,
				});
			} else {
				setApiData({ msg: "Error in processing." });
			}
		} catch (err) {
			console.log(err);
		}
	};

	const [apiData, setApiData] = useState({});

	// Load company data
	const loadPage = async () => {
		const submitUrl = apiRoot + `/v1/admin/company/${id}`;

		try {
			const res = await fetch(submitUrl, {
				method: "GET",
				headers: {
					token: localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
			});

			const data = await res.json();
			if (res.status === 200) {
				setApiData({ data: data.record });
			} else {
				setApiData({ msg: data.message });
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div>
			<h2 className='text-center'>Edit Company</h2>
			{apiData.msg && <p>{apiData.msg}</p>}

			{apiData.data && (
				<div className='mt-0 py-0 container text-center'>
					<form
						className=''
						method='post'
						onSubmit={handleSubmit(submitForm)}>
						<input
							type='text'
							className={`${errors.company_code && "error"}`}
							placeholder='Company Code'
							defaultValue={apiData.data.company_code}
							{...register("company_code", {
								required: true,
								message: "This field is required.",
							})}
						/>
						{errors.company_code && (
							<span className='error'>
								Please enter valid value.
							</span>
						)}
						<input
							type='text'
							className={`${errors.dsn && "error"}`}
							placeholder='DSN'
							defaultValue={apiData.data.dsn}
							{...register("dsn", {
								required: true,
								message: "This field is required.",
							})}
						/>
						{errors.dsn && (
							<span className='error'>
								Please enter valid value.
							</span>
						)}

						<input
							type='text'
							className={`${errors.name && "error"}`}
							placeholder='Company Name'
							defaultValue={apiData.data.name}
							{...register("name", {
								required: true,
								message: "This field is empty",
							})}
						/>
						{/* errors will return when field validation fails  */}
						{/* {errors.name && <p>{errors.name.message}</p>} */}

						{errors.name && (
							<span className='error'>
								Please enter valid value {errors.message}
							</span>
						)}

						<input
							type='email'
							className={`${errors.email && "error"}`}
							placeholder='Admin Email'
							defaultValue={apiData.data.Users[0].email}
							{...register("email", {
								required: true,
								message: "This field is required.",
							})}
						/>
						{errors.email && (
							<span className='error'>
								Please enter valid value.
							</span>
						)}
						<input
							type='text'
							className={`${errors.phone && "error"}`}
							placeholder='Admin Phone'
							defaultValue={apiData.data.phone}
							{...register("phone", {
								required: true,
								message: "This field is required.",
							})}
						/>
						{errors.phone && (
							<span className='error'>
								Please enter valid value.
							</span>
						)}
						<input
							type='text'
							className={`${errors.fname && "error"}`}
							placeholder='Admin First Name'
							defaultValue={apiData.data.Users[0].fname}
							{...register("fname", {
								required: true,
								message: "This field is required.",
							})}
						/>
						{errors.fname && (
							<span className='error'>
								Please enter valid value.
							</span>
						)}
						<input
							type='text'
							className={`${errors.lname && "error"}`}
							placeholder='Admin Last Name'
							defaultValue={apiData.data.Users[0].lname}
							{...register("lname", {
								required: true,
								message: "This field is required.",
							})}
						/>
						{errors.lname && (
							<span className='error'>
								Please enter valid value.
							</span>
						)}

						<input
							className='btn btn-lg fw-bold '
							type='submit'
							value='Save'
						/>
					</form>
				</div>
			)}
		</div>
	);
};

export default EditCompany;
