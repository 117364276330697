/** @format */

import React, { useContext, useState, useEffect } from "react";
// import useForm from "../Hooks/useForm";
import { useForm } from "react-hook-form";
import LoginValidationRule from "./validation/LoginValidationRule";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

import axios from "axios";

const apiRoot = process.env.REACT_APP_API_ROOT;

const LoginForm = (props) => {
	let navigate = useNavigate();
	// Context Value
	const value = useContext(AuthContext);

	const callbackForm = () => {
		submitForm();
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onChange" });

	// const authCtx = useContext(AuthContext);
	const submitForm = async (data) => {
		const submitUrl = apiRoot + "/v1/login";
		try {
			const response = await axios.post(submitUrl, data, {
				headers: {
					Accept: "application/json",
				},
			});

			if (response.status === 200) {
				const data = response.data;
				value.setLoginData(data);

				setApiData(data);
				setShowForm(false);

				value.notify({
					type: 1,
					msg: "You are successfully logged in.",
				});
				if (data.user.role === 5) {
					navigate("/admin");
				} else {
					navigate("/user");
				}
			}
		} catch (error) {
			value.notify({ type: 2, msg: error.response.data.message });
		}
	};

	const [apiData, setApiData] = useState({});
	const [showForm, setShowForm] = useState(true);

	useEffect(() => {
		// console.log("CTX Object" + value.user);
		// value.user.updateUser("I am from useContext");
		// fetchData();
		// console.log("I am in inside useEffect");
	}, []);

	return (
		<>
			<div className='mt-0 py-0 container text-center'>
				<h2 className='fw-bold border-white'>Login to My Account</h2>
				{/* <h3 className='error'>{apiData.msg}</h3> */}
				{showForm && (
					<form
						className='home-form login-form'
						method='post'
						onSubmit={handleSubmit(submitForm)}>
						<input
							className={`${errors.email && "error"}`}
							type='text'
							name='email'
							placeholder='Email'
							autoComplete='Off'
							{...register("email", {
								required: "Email is required",
								pattern: {
									value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: "Invalid email address",
								},
							})}
						/>
						{errors.email && (
							<span className='error'>
								{errors.email.message}
							</span>
						)}
						<input
							className={`${errors.password && "error"}`}
							type='password'
							name='password'
							placeholder='Password'
							autoComplete='Off'
							{...register("password", {
								required: "Password is required",
							})}
						/>
						{errors.password && (
							<span className='error'>
								{errors.password.message}
							</span>
						)}
						<input
							className='btn btn-lg fw-bold btn-success'
							type='submit'
							value='Login'
						/>
					</form>
				)}
			</div>
		</>
	);
};

export default LoginForm;
