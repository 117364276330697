/** @format */

import React, { useContext, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import useAuthCheck from "../../Hooks/useAuthCheck";

const DashboardHome = () => {
	const navigate = useNavigate();
	const context = useContext(AuthContext);

	// useAuthCheck(3);
	// Check Auth and Autorization

	const authError = useAuthCheck(3);
	// const authError = false;
	useEffect(() => {
		if (authError) {
			context.notify({ type: 2, msg: authError.errMessage });
			navigate("/");
		}
	}, []);

	return (
		<div className='container'>
			<div className='card'>
				<div className='card-body'>
					<h1>Welcome Back</h1>

					<p>
						You can check your Inventory, Purchase and Sales Record,
						Profit & Loss statement with just one click.
					</p>
					<ul className='horiz-menu'>
						<li className=''>
							<NavLink
								className={(isActive) =>
									"nav-link" +
									(!isActive ? " unselected" : "")
								}
								to='purchase'>
								Purchase
							</NavLink>
						</li>
						<li>
							<NavLink
								className={(isActive) =>
									"nav-link" +
									(!isActive ? " unselected" : "")
								}
								to='sale'>
								Sale
							</NavLink>
						</li>
						<li>
							<NavLink
								className={(isActive) =>
									"nav-link" +
									(!isActive ? " unselected" : "")
								}
								to='report'>
								Report
							</NavLink>
						</li>
						<li>
						<NavLink
							className={(isActive) =>
								"nav-link" + (!isActive ? " unselected" : "")
							}
							to='configure'>
							Configure
						</NavLink>
					</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default DashboardHome;
