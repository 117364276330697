import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Pricing from "../Pricing";

const apiRoot = ""; // "http://localhost:8000";
const User = () => {
	return (
		<div>
			<h1>I am in Admin - User list</h1>
			{/* <Routes>
				<Route path='/admin/pricing' element={<Pricing />}></Route>
			</Routes> */}
		</div>
	);
};

export default User;
