import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import AddCompany from "./AddCompany";
import CompanyDetail from "./CompanyDetail";
import DeleteCompany from "./DeleteCompany";
import EditCompany from "./EditCompany";
// import User from "../User";
import ListCompany from "./ListCompany";

const Company = () => {
	return (
		<div>
			<Link to=''>All Companies</Link> | <Link to='add'>Add Company</Link>
			{/* <Outlet /> */}
			<Routes>
				<Route path='/' element={<ListCompany />} />
				<Route path='/:id' element={<CompanyDetail />} />
				<Route path='add' element={<AddCompany />} />
				<Route path='edit/:id' element={<EditCompany />} />
				<Route path='delete/:id' element={<DeleteCompany />} />
			</Routes>
			{/* <Route path='company' element={<Company />}>
					<Route path='' element={<ListCompany />} />
					
			</Route> */}
		</div>
	);
};

export default Company;
