/** @format */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className='content'>
			<div className='container'>
				<div className='card'>
					<div className='card-body'>
						<h1 className='text-center py-3'>
							About Vyapar Sahayak
						</h1>
						<p>
							<strong>Vyapar Sahayak</strong> as the name suggests
							assist the small businesses in managing their day to
							day businesses operations with ease.
						</p>
						<p>
							The software takes care of handling purchase, sale,
							inventory, invoicing and other paper works enabling
							the businesse owner to focus on expanding their
							business.
						</p>
						<p>
							In today's era of digital world, still many
							businesses spend more time on tiring paper work.{" "}
						</p>
						<p>
							<strong>Vyapar Sahayak</strong> is your gateway to
							the digitized paper work so that you have more time
							to focus on growing your business.
						</p>
						<h2 className='text-center py-4'>
							<Link to='/'> Try Vyapar Sahayak today</Link>
						</h2>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
