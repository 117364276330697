/** @format */

import { Outlet } from "react-router-dom";

const SaleDetail = () => {
	return (
		<>
			<p>Manage all your sales data</p>
			<Outlet />
		</>
	);
};

export default SaleDetail;
