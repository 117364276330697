/** @format */

import { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useForm from "../Hooks/useForm";
import LoginValidationRule from "../form/validation/LoginValidationRule";
import AuthContext from "../context/AuthContext";
import axios from "axios";

const apiRoot = process.env.REACT_APP_API_ROOT;

const Navbar = () => {
	const context = useContext(AuthContext);
	let navigate = useNavigate();
	// Submit Form handling
	const callbackForm = () => {
		submitForm();
	};

	const { values, errors, handleChange, handleSubmit } = useForm(
		callbackForm,
		LoginValidationRule
	);

	const submitForm = async () => {
		const submitUrl = apiRoot + "/v1/login";
		try {
			const response = await axios.post(submitUrl, values, {
				headers: {
					Accept: "application/json",
				},
			});

			if (response.status === 200) {
				const data = response.data;
				context.setLoginData(data);

				context.notify({
					type: 1,
					msg: "You are successfully logged in.",
				});
				if (data.user.role === 5) {
					navigate("/admin");
				} else {
					navigate("/user");
				}
			}
		} catch (error) {
			context.notify({ type: 2, msg: error.response.data.message });
		}
	};

	// console.log(context.authToken);
	// Navbar collapse function
	const [isNavCollapsed, setIsNavCollapsed] = useState(true);

	const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

	return (
		<nav className='navbar navbar-expand-lg navbar-light'>
			<NavLink to='/' className='logo'>
				<h3 className=''>VyaparSahayak</h3>
			</NavLink>
			<button
				className='custom-toggler navbar-toggler'
				type='button'
				data-toggle='collapse'
				data-target='#navbarsExample09'
				aria-controls='navbarsExample09'
				aria-expanded={!isNavCollapsed ? true : false}
				aria-label='Toggle navigation'
				onClick={handleNavCollapse}>
				<span className='navbar-toggler-icon'></span>
			</button>

			<div
				className={`${
					isNavCollapsed ? "collapse" : ""
				} navbar-collapse`}
				id='navbarsExample09'>
				<ul className='navbar-nav me-auto mb-2 ps-3 mb-lg-0'>
					<li className='nav-item '>
						<NavLink
							className='nav-link '
							to='/'
							onClick={handleNavCollapse}>
							Home
						</NavLink>
					</li>
					<li className='nav-item'>
						<NavLink
							className='nav-link'
							to='/about'
							onClick={handleNavCollapse}>
							About
						</NavLink>
					</li>
					<li className='nav-item'>
						<NavLink
							className='nav-link'
							to='/features'
							onClick={handleNavCollapse}>
							Features
						</NavLink>
					</li>
					{/* <li className='nav-item'>
							<NavLink
								className='nav-link'
								to='/pricing'
								onClick={handleNavCollapse}>
								Pricing
							</NavLink>
						</li> */}
					<li className='nav-item'>
						<NavLink
							className='nav-link'
							to='/contact'
							onClick={handleNavCollapse}>
							Contact
						</NavLink>
					</li>
					{context.user && (
						<>
							<li className='nav-item'>
								<NavLink
									className='nav-link'
									to='/user'
									onClick={handleNavCollapse}>
									Dashboard
								</NavLink>
							</li>
							<li className='nav-item'>
								<NavLink
									className='nav-link'
									to='/logout'
									onClick={handleNavCollapse}>
									Logout
								</NavLink>
							</li>
						</>
					)}
				</ul>

				<div className='contact-head'>
					Have Queries! Contact Support
					<br />
					<i className='fa fa-at'></i> seontechnologies.in@gmail.com
					|&nbsp;
					<i className='fa fa-phone-alt'></i> +91 7011 401 800
					{!context.user ? (
						<form
							className='top-head-login-form py-1'
							method='post'
							onSubmit={handleSubmit}>
							<input
								className={`${errors.email && "error"}`}
								name='email'
								type='text'
								placeholder='Email'
								autoComplete='on'
								value={values.email || ""}
								onChange={handleChange}
							/>
							<input
								className={`${errors.password && "error"}`}
								name='password'
								type='password'
								placeholder='Password'
								autoComplete='off'
								value={values.password || ""}
								onChange={handleChange}
							/>
							<button className='btn'>Go</button>
						</form>
					) : (
						<p>Hello! {context.user ? context.user.email : ""}</p>
					)}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
