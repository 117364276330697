/** @format */

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthCheck from "../../../Hooks/useAuthCheck";

const apiRoot = process.env.REACT_APP_API_ROOT;
const CompanyDetail = () => {
	const navigate = useNavigate();
	// Auth check
	const authError = useAuthCheck(5);

	useEffect(() => {
		if (authError) {
			navigate("/", {
				state: authError.errMessage,
			});
		} else {
			loadPage();
		}
	}, []);

	const [apiData, setApiData] = useState({});
	const params = useParams();
	const id = params.id;
	// console.log(id);
	const loadPage = async () => {
		const submitUrl = apiRoot + `/v1/admin/company/${id}`;

		try {
			const res = await fetch(submitUrl, {
				method: "GET",
				headers: {
					token: localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
			});

			if (res.status === 200) {
				const data = await res.json();
				setApiData(data.record);
			} else {
				setApiData({ msg: "Error in processing." });
			}
		} catch (err) {
			console.log(err);
		}
	};

	const location = useLocation();

	return (
		<>
			{apiData != undefined && (
				<div>
					<h1>Company Detail</h1>
					<div>
						<Link
							to={`/admin/company/edit/${apiData.id}`}
							className='btn btn-primary btn-lg m-3'>
							Edit
						</Link>
						<Link
							to={`/admin/company/delete/${apiData.id}`}
							className='btn btn-danger btn-lg'>
							Delete
						</Link>
					</div>

					<div className='row'>
						<div className='col'>
							<h3>Company Info</h3>
							<table className='table'>
								<tbody>
									<tr>
										<td>Id</td>
										<td>{apiData.id}</td>
									</tr>
									<tr>
										<td>Name</td>
										<td>{apiData.name}</td>
									</tr>
									<tr>
										<td>Code</td>
										<td>{apiData.company_code}</td>
									</tr>
									<tr>
										<td>DSN</td>
										<td>{apiData.dsn}</td>
									</tr>
									<tr>
										<td>Phone</td>
										<td>{apiData.phone}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='col'>
							<h3>Company Users</h3>

							<table className='table'>
								<thead>
									<tr>
										<th>Id</th>
										<th>Email</th>
										<th>Role</th>
									</tr>
								</thead>
								<tbody>
									{apiData.Users != undefined &&
										apiData.Users.map((user, index) => (
											<tr key={index} data-index={index}>
												<td>{user.id}</td>
												<td>{user.email}</td>
												<td>{user.role}</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CompanyDetail;
