export default function validate(values) {
	let errors = {};
	if (!values.name) {
		errors.name = "Name is required";
	}
	if (!values.email) {
		errors.email = "Email address is required";
	} else if (!/\S+@\S+\.\S+/.test(values.email)) {
		errors.email = "Email address is invalid";
	}
	if (!values.mobile) {
		errors.mobile = "Mobile is required";
	}
	if (!values.company) {
		errors.company = "Company name is required";
	}
	if (!values.description) {
		errors.description = "Please provide your query";
	}

	return errors;
}
