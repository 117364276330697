/** @format */

import React, { useEffect } from "react";
// import { AuthContext } from "../context/AuthContext";
import ContactForm from "../form/ContactForm";

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	// Get Values from context
	// const context = useContext(AuthContext);
	return (
		<div className='container'>
			<div className='card'>
				<div className='card-body'>
					<h1 className='text-center'>Contact Us</h1>
					<p>Drop a line to us. We respond quick.</p>
					<ContactForm />
				</div>
			</div>
		</div>
	);
};

export default Contact;
