/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";

import axios from "axios";
import LoadingSpinner from "../../../../common/LoadingSpinner";
import AuthCheck from "../../../../common/AuthCheck";
import Select from "react-select";

const apiRoot = process.env.REACT_APP_API_ROOT;
const EditRecord = () => {
  const context = useContext(AuthContext);
  // Auth check
  const navigate = useNavigate();
  const params = useParams();

  // Load initial form data
  const [apiData, setApiData] = useState(false);
  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);
  const id = params.id;

  const [totalCost, setTotalCost] = useState({
    itemCost: 0,
    otherExpense: 0,
    totalCost: 0,
  });

  const loadData = async () => {
    const submitUrl = `${process.env.REACT_APP_API_ROOT}/v1/user/purchase/${id}?cmp=${context.user.company}&id=${id}`;
    try {
      const response = await axios.get(submitUrl, {
        headers: {
          token: localStorage.getItem("token"),
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setApiData(data.record);
        setLoading(false);

        setTotalCost({
          itemCost: data.record.total_cost,
          otherExpense: data.record.other_expense,
          totalCost:
            parseFloat(data.record.total_cost) +
            parseFloat(data.record.other_expense),
        });
      }
    } catch (error) {
      setApiData(false);
      setLoading(false);
      if (error.response.status === 401) {
        context.logout();
      }

      context.notify({ type: 2, msg: error.response.data.error });
    }
  };

  // Auth check
  const authError = AuthCheck(3);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (authError) {
      navigate("/");
    }
    if (context.user) {
      loadData();
    }

    //   Load product
    const loadProducts = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_ROOT}/v1/user/product/list/`;
        const response = await axios.get(apiUrl, {
          headers: {
            token: localStorage.getItem("token"),
            Accept: "application/json",
          },
        });

        setProducts(response.data.records);
        // return (products = response.data.records);
        //   console.log("OKKKK", products);
      } catch (error) {
        if (error.response.status === 401) {
          context.logout();
        }
      }
    };

    loadProducts();
  }, []);

  // React form hook code starts
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  // const onSubmit = (data) => console.log(data);

  const submitForm = async (data) => {
    const submitUrl = apiRoot + `/v1/user/purchase/${id}`;
    try {
      const response = await axios.put(submitUrl, data, {
        headers: {
          token: localStorage.getItem("token"),
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        context.notify({ type: 1, msg: response.data.message });

        navigate("/user/purchase");
      }
    } catch (err) {
      context.notify({ type: 2, msg: err.response.data.error });
    }
  };

  // Update total cost as per price and quantity

  const update_total = (e) => {
    let qty = parseFloat(document.querySelector("#quantity").value);
    let price = parseFloat(document.querySelector("#price").value);
    let otherExpense = parseFloat(
      document.querySelector("#other-expense").value
    );

    qty = isNaN(qty) ? 0 : qty;
    price = isNaN(price) ? 0 : price;
    otherExpense = isNaN(otherExpense) ? 0 : otherExpense;

    let itemCost = qty * price;
    let totalCost = itemCost;
    if (otherExpense > 0) {
      totalCost += otherExpense;
    }
    setTotalCost({
      itemCost: itemCost.toFixed(2),
      otherExpense: otherExpense.toFixed(2),
      totalCost: totalCost.toFixed(2),
    });
  };

  // Calculate balance due
  const update_balance = () => {
    let paid = parseFloat(document.querySelector("#paid").value);
    let balance = (totalCost.totalCost - paid).toFixed(2).toString();

    setValue("due", balance);
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <h2 className="text-center">Edit Record</h2>
      {apiData.msg && <p>{apiData.msg}</p>}

      {apiData && (
        <div className="mt-0 py-0 container text-center">
          <form className="" method="post" onSubmit={handleSubmit(submitForm)}>
            <div className="form-group">
              <input className="btn btn-success " type="submit" value="Save" />
              &nbsp;
              <Link to="/user/purchase" className="btn btn-danger">
                Cancel
              </Link>
            </div>
            <div className="form-group">
              <label>Purchase Order No / Bill No</label>
              <input
                type="text"
                className={`${errors.po_number && "error"}`}
                placeholder="Purchase Order No / Bill No"
                defaultValue={apiData.po_detail.po_number}
                {...register("po_number", {
                  required: "Purchase Order Number/Bill is required",
                })}
              />
              {errors.po_number && (
                <span className="error">{errors.po_number.message}</span>
              )}
            </div>
            <div className="form-group">
              <label>Product</label>
              <Controller
                control={control}
                name="product_id"
                placeholder="Product"
                defaultValue={apiData.product_id}
                className={`${errors.product_id && "error"}`}
                rules={{
                  required: "Product is required.",
                }}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    inputRef={ref}
                    options={products}
                    //   onBlur={onBlur}
                    selected={value}
                    // isOptionSelected={apiData.sale_detail.product_id}
                    value={products.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                  />
                )}
              />
              {/* <input
                type="text"
                className={`${errors.item && "error"}`}
                placeholder="Item"
                defaultValue={apiData.item}
                {...register("item", {
                  required: "Purchase item is required",
                  message: "",
                })}
              /> */}
              {errors.product_id && (
                <span className="error">{errors.product_id.message}</span>
              )}
            </div>

            <div className="form-group">
              <label>Item Quantity</label>
              <input
                type="text"
                id="quantity"
                className={`${errors.quantity && "error"}`}
                placeholder="Quantity"
                defaultValue={apiData.quantity}
                autoComplete="Off"
                {...register("quantity", {
                  onChange: (e) => update_total(e),
                  required: "Purchase quantity is required",
                  // valueAsNumber: true,
                  pattern: {
                    // value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    value: /[0-9]+$/,
                    message: "Please enter number only",
                  },
                  validate: {
                    positive: (v) =>
                      parseInt(v) > 0 || "Quantity must be greater than 0.",
                  },
                })}
              />
              {errors.quantity && (
                <span className="error">{errors.quantity.message}</span>
              )}
            </div>

            <div className="form-group">
              <label>Unit Rate</label>
              <input
                type="text"
                id="price"
                className={`${errors.rate && "error"}`}
                placeholder="Unit Rate"
                defaultValue={apiData.rate}
                autoComplete="Off"
                // onBlurCapture={update_total}
                {...register("rate", {
                  onChange: (e) => update_total(e),
                  required: "Unit price is required",
                  pattern: {
                    // value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    value: /[0-9]+$/,
                    message: "Please enter number only",
                  },
                })}
              />
              {errors.rate && (
                <span className="error">{errors.rate.message}</span>
              )}
            </div>
            <div className="total-cost-hld">
              <p>
                Total Cost: Total Cost: <i className="fas fa-rupee-sign"></i>{" "}
                {totalCost.itemCost}
              </p>
              {totalCost.otherExpense > 0 && (
                <p>
                  Other Expense: <i className="fas fa-rupee-sign"></i>{" "}
                  {totalCost.otherExpense}
                </p>
              )}
              {totalCost.totalCost && (
                <p>
                  Total Cost: <i className="fas fa-rupee-sign"></i>{" "}
                  {totalCost.totalCost}
                </p>
              )}
            </div>

            <div className="form-group py-3">
              <label className="form-label">Item Unit</label>
              <div className="form-check form-check-inline">
                <input
                  id="kg"
                  type="radio"
                  className={`${errors.unit && "error"} form-check-input`}
                  placeholder="Unit"
                  defaultValue="kg"
                  defaultChecked={apiData.unit === "kg" ? true : false}
                  {...register("unit", {
                    required: "Unit is required",
                    message: "",
                  })}
                />
                <label className="form-check-label" htmlFor="kg">
                  KG
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  id="piece"
                  type="radio"
                  className={`${errors.unit && "error"} form-check-input`}
                  placeholder="Unit"
                  defaultValue="piece"
                  defaultChecked={apiData.unit === "piece" ? true : false}
                  {...register("unit", {
                    required: "Unit is required",
                    message: "",
                  })}
                />
                <label className="form-check-label" htmlFor="piece">
                  Piece
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  id="box"
                  type="radio"
                  className={`${errors.unit && "error"} form-check-input`}
                  placeholder="Unit"
                  defaultValue="box"
                  defaultChecked={apiData.unit === "box" ? true : false}
                  {...register("unit", {
                    required: "Unit is required",
                    message: "",
                  })}
                />
                <label className="form-check-label" htmlFor="box">
                  Box
                </label>
              </div>

              {errors.unit && (
                <span className="error">{errors.unit.message}</span>
              )}
            </div>

            <div className="form-group">
              <small>
                Other expense like transport, installation, fitting. Provide 0
                if there is no other expense.
              </small>
              <label>Other Expense</label>
              <input
                id="other-expense"
                type="text"
                className={`${errors.other_expense && "error"}`}
                placeholder="Other Expense"
                defaultValue={apiData.other_expense}
                {...register("other_expense", {
                  onChange: (e) => update_total(),
                  // valueAsNumber: true,
                  // setValueAs: (value) =>
                  // 	value === "" ? "0" : +value,

                  pattern: {
                    value: /[0-9]+$/,
                    message: "Please enter number only",
                  },
                })}
              />
              {errors.other_expense && (
                <span className="error">{errors.other_expense.message}</span>
              )}
            </div>

            <div className="form-group">
              <label>Amount Paid</label>
              <input
                type="text"
                id="paid"
                className={`${errors.paid && "error"}`}
                placeholder="Amount Paid"
                defaultValue={apiData.paid}
                autoComplete="off"
                {...register("paid", {
                  onChange: (e) => update_balance(e),
                  required: "Paid amout is required.",
                  pattern: {
                    value: /[0-9]+$/,
                    message: "Please enter number only",
                  },
                })}
              />
              {errors.paid && (
                <span className="error">{errors.paid.message}</span>
              )}
            </div>
            <div className="form-group">
              <label>Balance Due</label>
              <input
                type="text"
                id="balance"
                className={`${errors.due && "error"}`}
                placeholder="Balance Due"
                defaultValue={apiData.due}
                readOnly
                {...register("due", {
                  pattern: {
                    value: /[0-9]+$/,
                    message: "Please enter number only",
                  },
                })}
              />
              <span>{errors.due?.message}</span>

              {errors.due && (
                <span className="error">{errors.due.message}</span>
              )}
            </div>

            <div className="form-group">
              <input className="btn btn-success " type="submit" value="Save" />
              &nbsp;
              <Link to="/user/purchase" className="btn btn-danger">
                Cancel
              </Link>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditRecord;
