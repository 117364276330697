/** @format */

import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../css/home.css";
import "../css/form.css";
import LoginForm from "../form/LoginForm";
import AuthContext from "../context/AuthContext";

const Home = () => {
	const context = useContext(AuthContext);
	// const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);

		// console.log(location.state);
		// if (location.state) {
		// 	{
		// 		context.notity({ type: 2, msg: "OK---" + location.msg });
		// 	}
		// }
	}, []);

	return (
		<>
			{/* {location.state && (
				{context.notity({type:2, msg:location.msg})}
				{/* <div
					className='container alert alert-danger d-flex align-items-center'
					role='alert'>
					{location.state}
				</div> 
			*/}
			<div className='container h-90 text-center'>
				<header className='mb-5 px-1'>
					<div className='home-header'>
						<h1 className='text-center mb-0 py-4 fw-bold'>
							Vyapar Sahayak
						</h1>
						<p>
							No tiring paper work. Gateway to the digitized paper
							work{" "}
						</p>
					</div>
				</header>
				<section className='mt-2 py-2'>
					<div className='row'>
						<div className='col-sm-8 px-5 text-start'>
							<h2>Business with Ease</h2>
							<p className='lead'>
								Inventory | Billing | Taxation
							</p>
							<div className='container'>
								<div className=''>
									<ul className='text-start'>
										<li>
											<span className='feature'>
												<i className='fa fa-check-circle fa-2x'></i>
												Track Inventory on your
												Smartphone
											</span>
										</li>
										<li>
											<span className='feature'>
												<i className='fa fa-check-circle fa-2x'></i>
												Sale/Purchase order wherever you
												are
											</span>
										</li>
										<li>
											<span className='feature'>
												<i className='fa fa-check-circle fa-2x'></i>
												Real time updates of your
												inventory
											</span>
										</li>
										<li>
											<span className='feature'>
												<i className='fa fa-check-circle fa-2x'></i>
												No Installation
											</span>
										</li>
										<li>
											<span className='feature'>
												<i className='fa fa-check-circle fa-2x'></i>
												Easy to Use and Operate
											</span>
										</li>
									</ul>
								</div>
							</div>

							<p className='lead'>
								<NavLink
									to='/contact'
									className='btn btn-lg fw-bold border-white bg-white'>
									Request Callback
								</NavLink>
							</p>
						</div>
						<div className='col'>
							{context.user ? (
								<>
									<h5>
										<NavLink
											className='nav-link'
											to='/user'>
											Dashboard
										</NavLink>
									</h5>
									<h5>
										<NavLink
											className='nav-link'
											to='/logout'>
											Logout
										</NavLink>
									</h5>
								</>
							) : (
								<LoginForm />
							)}
						</div>
					</div>
				</section>
			</div>
			{/* <Footer /> */}
		</>
	);
};

export default Home;
