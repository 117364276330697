/** @format */

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadingSpinner from "../../../common/LoadingSpinner";
import Pagination from "../../../common/Pagination";
import AuthContext from "../../../context/AuthContext";
// import useAuthCheck from "../../../Hooks/useAuthCheck";
import SaleDetail from "./util/SaleDetail";

const apiRoot = process.env.REACT_APP_API_ROOT;

const SaleList = () => {
  const context = useContext(AuthContext);

  // Auth check
  // const navigate = useNavigate();
  // const authError = useAuthCheck(3);

  const location = useLocation();

  const [apiData, setApiData] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(true);

  // Company ID
  const id = context.user.company;

  const loadPage = async (pageNo) => {
    const submitUrl = apiRoot + `/v1/user/sale/?page=${pageNo}`;

    try {
      const res = await axios.get(submitUrl, {
        headers: {
          token: localStorage.getItem("token"),
          Accept: "application/json",
        },
      });

      setApiData({ data: res.data.records });
      setTotalPage(res.data.total_pages);
      setLoading(false);
    } catch (err) {
      setApiData(false);
      setLoading(false);
      if (err.response.status === 401) {
        context.logout();
      }

      context.notify({ type: 2, msg: err.response.data.error });
    }
  };
  // }
  useEffect(() => {
    // if (authError) {
    // 	navigate("/");
    // }

    if (context.user) {
      if (context.user.company) {
        loadPage(1);
      }
    }
  }, [id]);

  const handlePagination = (pageNo) => {
    // console.log(pageNo);
    // console.log(tcurPage.target.dataset.id);

    setLoading(true);
    loadPage(pageNo);
    setCurPage(pageNo);
  };
  // console.log(apiData);
  // if (apiData) {
  // 	apiData.data.map((record) => {
  // 		console.log(record);
  // 	});
  // }

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div className="mb-1">
        <Link className="btn btn-success" to={`${location.pathname}/add`}>
          Add New
        </Link>
      </div>

      {!apiData && (
        <>
          <div className="mb-1">
            <h3 className="text-center py-5">No Records found.</h3>
          </div>
        </>
      )}

      <>
        {apiData && (
          <>
            <Pagination
              totalPages={totalPages}
              curPage={curPage}
              handlePagination={handlePagination}
            />
            <div className="row">
              {apiData.data.map((record, i) => (
                <div className="col-sm-12" key={i}>
                  <div className="flex">
                    <ul className="horizontal">
                      <li>
                        <h3>Invoice No: {record.invoice}</h3>
                      </li>
                      <li className="">
                        <Link
                          to={`delete/invoice/${record.id}`}
                          className="card-link"
                          title="Delete Invoice and its Orders"
                        >
                          <i className="fa fa-trash fa-2x"></i>
                        </Link>
                      </li>
                    </ul>
                    <span></span>
                  </div>
                  <SaleDetail order={record.sale_detail} />
                </div>
              ))}
            </div>
          </>
        )}
      </>
    </>
  );
};

export default SaleList;
