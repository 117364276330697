/** @format */

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuthCheck from "../../../Hooks/useAuthCheck";

// const apiRoot = "";
const apiRoot = process.env.REACT_APP_API_ROOT;
const ListCompany = () => {
	const navigate = useNavigate();
	const authError = useAuthCheck(5);

	const [apiData, setApiData] = useState({});
	const loadPage = async () => {
		const submitUrl = apiRoot + "/v1/admin/company";

		try {
			const res = await fetch(submitUrl, {
				method: "GET",
				headers: {
					token: localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
			});

			if (res.status === 200) {
				const data = await res.json();
				setApiData(data);
			} else {
				setApiData({ msg: "Error in processing." });
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		console.log(authError);
		if (!authError) {
			loadPage();
		} else {
			if (authError.error) {
				// Handle auth error
				navigate("/", {
					state: authError.errMessage,
				});
			}
		}
	}, []);
	const location = useLocation();
	return (
		<div>
			<h4>Company List</h4>
			{location.state && (
				<div className='alert alert-info'>{location.state}</div>
			)}
			{/* {apiData} */}
			<div>
				<table className='table table-bordered'>
					<thead>
						<tr>
							<th>Id</th>
							<th>Name</th>
							<th>DSN</th>
							<th>Active</th>
							<th></th>
						</tr>
					</thead>
					{apiData.data && (
						<tbody>
							{apiData.data.map((record, index) => (
								<tr key={index} data-index={index}>
									<td>{record.id}</td>
									<td>
										<Link
											to={`/admin/company/${record.id}`}>
											{record.name}
										</Link>
									</td>
									<td>{record.dsn}</td>
									<td>
										{!record.isActive
											? "Disabled"
											: "Active"}
									</td>
									<td>
										<Link
											to={`/admin/company/edit/${record.id}`}
											className=''>
											<i className='fa fa-pen'></i>
										</Link>

										<Link
											to={`/admin/company/delete/${record.id}`}
											className=' '>
											<i className='fa fa-trash'></i>
										</Link>
									</td>
								</tr>
							))}
						</tbody>
					)}
				</table>
			</div>
		</div>
	);
};

export default ListCompany;
