import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";

import axios from "axios";

const AddProduct = () => {
  const context = useContext(AuthContext);
  // Auth check
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  // const onSubmit = (data) => console.log(data);

  const submitForm = async (data) => {
    const submitUrl = `${process.env.REACT_APP_API_ROOT}/v1/user/product/`;
    try {
      const response = await axios.post(submitUrl, data, {
        headers: {
          token: localStorage.getItem("token"),
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        context.notify({ type: 1, msg: response.data.message });

        navigate("/user/configure/product");
      }
    } catch (error) {
      if (error.response.status === 401) {
        context.logout();
      }
      context.notify({ type: 2, msg: error.response.data.error });
    }
  };

  return (
    <div>
      <h2 className="text-center">Add New Product</h2>

      <div className="mt-0 py-0 container text-center">
        <form className="" method="post" onSubmit={handleSubmit(submitForm)}>
          <div className="form-group">
            <input className="btn btn-success " type="submit" value="Save" />

            <Link to="/user/configure/product" className="btn btn-danger">
              Cancel
            </Link>
          </div>
          <div className="form-group">
            <label>Product Name</label>
            <input
              type="text"
              className={`${errors.name && "error"}`}
              placeholder="Product Name"
              defaultValue={""}
              {...register("name", {
                required: "Product Name is required",
              })}
            />
            {errors.name && (
              <span className="error">{errors.name.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>Regular Selling Price</label>
            <input
              type="text"
              id="price"
              className={`${errors.price && "error"}`}
              placeholder="Regular Selling Price"
              // defaultValue={apiData.product_price.price}
              autoComplete="Off"
              // onBlurCapture={update_total}
              {...register("price", {
                required: "Regular Selling Price is required",
                pattern: {
                  value: /[0-9]+$/,
                  message: "Please enter number only",
                },
              })}
            />
            {errors.price && (
              <span className="error">{errors.price.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>Offer Selling Price</label>
            <input
              type="text"
              id="price"
              className={`${errors.offer_price && "error"}`}
              placeholder="Offer Selling Price"
              // defaultValue={apiData.product_price.offer_price}
              autoComplete="Off"
              // onBlurCapture={update_total}
              {...register("offer_price", {
                pattern: {
                  value: /[0-9]+$/,
                  message: "Please enter number only",
                },
              })}
            />
            {errors.offer_price && (
              <span className="error">{errors.offer_price.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>Product Code</label>
            <input
              type="text"
              className={`${errors.product_code && "error"}`}
              placeholder="Product Code"
              defaultValue={""}
              {...register("product_code", {
                required: "Product Code is required",
              })}
            />
            {errors.product_code && (
              <span className="error">{errors.product_code.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>Barcode</label>
            <input
              type="text"
              id="barcode"
              className={`${errors.barcode && "error"}`}
              placeholder="Barcode"
              defaultValue={""}
              autoComplete="Off"
              {...register("barcode", {
                required: "Barcode is required",
              })}
            />
            {errors.barcode && (
              <span className="error">{errors.barcode.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>Inventory</label>
            <input
              type="text"
              id="available"
              className={`${errors.available && "error"}`}
              placeholder="Available Inventory"
              defaultValue="0"
              autoComplete="Off"
              {...register("available", {
                required: "Inventory is required",
                pattern: {
                  value: /[0-9]+$/,
                  message: "Please enter number only",
                },
              })}
            />
            {errors.available && (
              <span className="error">{errors.available.message}</span>
            )}
          </div>

          <div className="form-group">
            <label>Minimum Availability</label>
            <input
              type="text"
              id="minimum_availability"
              className={`${errors.minimum_availability && "error"}`}
              placeholder="Minimum Availability"
              defaultValue="0"
              autoComplete="Off"
              {...register("minimum_availability", {
                required: "Minimum Availability is required",
                pattern: {
                  value: /[0-9]+$/,
                  message: "Please enter number only",
                },
              })}
            />
            {errors.minimum_availability && (
              <span className="error">
                {errors.minimum_availability.message}
              </span>
            )}
          </div>

          <div className="form-group py-3">
            <label className="form-label">Active</label>
            <div className="form-check form-check-inline">
              <input
                id="is_active"
                type="checkbox"
                className={`${errors.is_active && "error"} form-check-input`}
                // defaultValue={false}
                placeholder="Active"
                {...register("is_active", {
                  message: "",
                })}
              />
              <label className="form-check-label" htmlFor="is_active">
                Active
              </label>
            </div>

            {errors.is_active && (
              <span className="error">{errors.is_active.message}</span>
            )}
          </div>
          <div className="form-group">
            <input className="btn btn-success " type="submit" value="Save" />
            &nbsp;
            <Link to="/user/configure/product" className="btn btn-danger">
              Cancel
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProduct;
