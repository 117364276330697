import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
	return (
		<div className='container text-center'>
			<h1>Page not found.</h1>
			<p>
				<Link to='/'>Home</Link>
			</p>
		</div>
	);
};

export default NotFound;
