/** @format */

import { useContext, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Home from "./pages/Home";
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import About from "./pages/About";
import Feature from "./pages/Feature";
import Contact from "./pages/Contact";
import Pricing from "./pages/Pricing";
import UserDashboard from "./pages/User/UserDashboard";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Product from "./pages/Admin/Product";
import Logout from "./pages/Logout";
import NotFound from "./NotFound";
import AuthContext from "./context/AuthContext";
import List from "./pages/product/List";
import ProductHome from "./pages/product/ProductHome";

import { Toaster } from "react-hot-toast";

function App() {
	let location = useLocation();
	// Get User detail
	const context = useContext(AuthContext);
	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token !== null) {
			// console.log("Fetch User");
			context.fetchUser();
		}

		return () => {};
	}, []);
	return (
		<>
			{/* <button onClick={notify}>Make me a toast</button> */}
			<Toaster position='top-right' reverseOrder={false} />
			<>
				<div className='h-100'>
					{/* {location == "/" ? <Header /> : ""} */}
					{location.pathname !== "/" && <Header />}

					<Routes>
						<Route exact path='/' element={<Home />}></Route>
						<Route path='/about' element={<About />}></Route>
						<Route path='/features' element={<Feature />}></Route>
						<Route path='/contact' element={<Contact />}></Route>
						<Route path='/pricing' element={<Pricing />}></Route>
						<Route path='/user/*' element={<UserDashboard />} />
						<Route path='/admin/*' element={<AdminDashboard />} />

						<Route path='/product' element={<ProductHome />}>
							<Route path='' element={<List />} />
							<Route path='edit' element={<Product />} />
							{/* <Route path='page1' element={<User />}></Route> */}
							{/* <Route path='page2' element={<Product />}></Route> */}
						</Route>

						<Route path='/logout' element={<Logout />}></Route>

						<Route path='*' element={<NotFound />} />
						{/* <Route from='*' to='/' /> */}
					</Routes>
					<Footer />
				</div>
			</>
		</>
	);
}

export default App;
