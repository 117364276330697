/** @format */

import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../../NotFound";
import MasterDashboard from "./MasterDashboard";
import ProductList from "./ProductList";
import AddProduct from "./form/AddProduct";
import EditProduct from "./form/EditProduct";
import DeleteProduct from "./DeleteProduct";
import ProductDetail from "./ProductDetail";

const Master = () => {
  // Auth Check
  useEffect(() => {}, []);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title text-center">Configure Master Data</h2>
          <Routes>
            <Route path="" element={<MasterDashboard />}>
              <Route path="/product" element={<ProductList />} />
              <Route path="/product/:id" element={<ProductDetail />} />
              <Route path="/product/add" element={<AddProduct />} />
              <Route path="/product/edit/:id" element={<EditProduct />} />
              <Route path="/product/delete/:id" element={<DeleteProduct />} />

              {/* <Route path="/product/edit" element={<AddProduct />} /> */}

              {/* <Route index element={<SaleList />} />
							<Route
								path='/detail/:id'
								element={<SaleDetail />}
							/>
							<Route path='/add' element={<AddRecord />} />
							<Route path='/edit/:id' element={<EditRecord />} />
							<Route
								path='delete/:id'
								element={<DeleteRecord />}
							/>
							<Route
								path='delete/invoice/:id'
								element={<DeleteInvoice model='invoice' />}
							/> */}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Master;
