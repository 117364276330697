import React from "react";
import { Link, Outlet } from "react-router-dom";

const ProductHome = () => {
	return (
		<div>
			<h1>Product Home</h1>
			<Link to=''>List</Link> | <Link to='edit'>Edit</Link>
			<Outlet />
		</div>
	);
};

export default ProductHome;
