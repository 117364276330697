/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../../common/LoadingSpinner";
import AuthContext from "../../../context/AuthContext";

import axios from "axios";
import AuthCheck from "../../../common/AuthCheck";

function PurchaseDetail() {
  const params = useParams();
  const context = useContext(AuthContext);
  const id = params.id;
  const [apiData, setApiData] = useState(false);
  const [loading, setLoading] = useState(true);

  // Load page
  const loadPage = async (pageNo) => {
    const submitUrl = `${process.env.REACT_APP_API_ROOT}/v1/user/purchase/${id}?cmp=${context.user.company}&id=${id}`;
    try {
      const response = await axios.get(submitUrl, {
        headers: {
          token: localStorage.getItem("token"),
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setApiData({ data: data.record });
        setLoading(false);
      }
    } catch (error) {
      setApiData(false);
      setLoading(false);
      if (error.response.status === 401) {
        context.logout();
      }
      context.notify({ type: 2, msg: error.response.data.error });
    }
  };

  // Auth Check
  const authError = AuthCheck(3);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (authError) {
      navigate("/");
    }

    if (context.user) {
      loadPage();
    }
  }, [context.user]);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <h3>Your purchase details</h3>
      {apiData && (
        <div>
          <div>
            <Link
              to={`/user/purchase/edit/${apiData.data.id}`}
              className="btn btn-success"
            >
              Edit
            </Link>
            <Link
              to={`/user/purchase/delete/${apiData.data.id}`}
              className="btn btn-danger"
            >
              Delete
            </Link>
          </div>
          <div className="table-responsive py-3">
            <table className="table table-hover table-responsive">
              <tbody>
                <tr>
                  <td>
                    <label>Purchase Order</label>
                  </td>
                  <td colSpan={5}>{apiData.data.po_detail.po_number}</td>
                </tr>
                <tr>
                  <td>
                    <label>Product</label>
                  </td>
                  <td>{apiData.data.product.name}</td>
                  <td>
                    <label>Date</label>
                  </td>
                  <td>{apiData.data.created_at}</td>
                  <td>
                    <label>Unit</label>
                  </td>
                  <td>{apiData.data.unit}</td>
                </tr>
                <tr>
                  <td>
                    <label>Quantity</label>
                  </td>
                  <td>{apiData.data.quantity}</td>
                  <td>
                    <label>Rate</label>
                  </td>
                  <td>
                    {apiData.data.rate}
                    {/* {(1234.56789).toLocaleString(
											undefined,
											{
												maximumFractionDigits: 2,
											}
										)} */}
                  </td>
                  <td>
                    <label>Total </label>
                  </td>
                  <td>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(apiData.data.quantity * apiData.data.rate)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Other Expense</label>
                  </td>
                  <td>{apiData.data.other_expense}</td>
                  <td></td>
                  <td></td>
                  <td>
                    <label>Total + Other Expense</label>
                  </td>
                  <td>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(
                      apiData.data.quantity * apiData.data.rate +
                        parseFloat(apiData.data.other_expense)
                    )}
                    {}
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Amount Paid</label>
                  </td>
                  <td>
                    <b>{apiData.data.paid}</b>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <label>Balance Due</label>
                  </td>
                  <td>
                    <b>
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(apiData.data.due)}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default PurchaseDetail;
