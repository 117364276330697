/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuthCheck from "../../../Hooks/useAuthCheck";

const apiRoot = process.env.REACT_APP_API_ROOT;

const DeleteCompany = () => {
	const navigate = useNavigate();
	const authError = useAuthCheck(5);

	const [apiData, setApiData] = useState({});
	const params = useParams();
	const id = params.id;
	// console.log(id);
	const loadPage = async () => {
		const submitUrl = apiRoot + `/v1/admin/company/${id}`;

		try {
			const res = await fetch(submitUrl, {
				method: "DELETE",
				headers: {
					token: localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
			});

			const data = await res.json();
			if (res.status === 200) {
				navigate("/admin/company", {
					state: data.message,
				});
			} else {
				setApiData({ msg: data.message });
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (authError) {
			navigate("/", {
				state: authError.errMessage,
			});
		} else {
			loadPage();
		}
	}, []);

	return (
		<>
			{apiData != undefined && (
				<div>
					<h1>{apiData.msg}</h1>
				</div>
			)}
		</>
	);
};

export default DeleteCompany;
