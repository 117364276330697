/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../../common/Pagination";
import AuthContext from "../../../context/AuthContext";

import Moment from "moment";
import LoadingSpinner from "../../../common/LoadingSpinner";

import axios from "axios";

const api_root = process.env.REACT_APP_API_ROOT;
const Purchase = () => {
  const context = useContext(AuthContext);
  const [apiData, setApiData] = useState({});
  const [curPage, setCurPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const loadPage = async (pageNo) => {
    const submitUrl =
      api_root + `/v1/user/purchase/?id=${context.user.company}&page=${pageNo}`;
    try {
      const response = await axios.get(submitUrl, {
        headers: {
          Accept: "application/json",
          token: localStorage.getItem("token"),
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setApiData({ data: data.records });
        setLoading(false);

        setTotalPage(data.total_pages);
      }
    } catch (error) {
      setApiData();
      setLoading(false);
      context.notify({ type: 2, msg: error.response.data.error });
      if (error.response.status === 401) {
        context.logout();
      }
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);

    if (context.user) {
      loadPage(1);
    }
  }, [context.user]);

  const handlePagination = (pageNo) => {
    setLoading(true);
    loadPage(pageNo);
    setCurPage(pageNo);
  };

  const location = useLocation();

  if (loading) {
    return <LoadingSpinner />;
  }
  // console.log(apiData);
  return (
    <div>
      <h2>Purchase Report</h2>
      {!apiData && (
        <>
          <div className="mb-1">
            <h3 className="text-center py-5">No Records found.</h3>
          </div>
        </>
      )}

      {
        <>
          {apiData && (
            <>
              <Pagination
                totalPages={totalPages}
                curPage={curPage}
                handlePagination={handlePagination}
              />
              <div className="table-responsive">
                <table className="table py-3">
                  <thead>
                    <tr>
                      <th width="100">Id</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Rate</th>
                      <th>Total</th>
                      <th>Total+Other</th>
                      <th>Paid</th>
                      <th>Balance</th>
                      <th>Date</th>
                      <th width="100"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiData.data.map((record, index) => (
                      <tr key={index} data-index={index}>
                        <td>{record.id}</td>
                        <td>
                          <Link to={`detail/${record.id}`} className=" ">
                            {record.product.name}
                          </Link>
                        </td>
                        <td>{record.quantity}</td>
                        <td>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                          }).format(record.rate)}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                          }).format(record.total_cost)}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                          }).format(
                            parseFloat(record.total_cost) +
                              parseFloat(record.other_expense)
                          )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                          }).format(record.paid)}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                          }).format(record.due)}
                        </td>
                        <td>
                          {/* {new Intl.DateTimeFormat(
													"en-GB"
												).format(record.created_at)} */}
                          {Moment(record.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <Link to={`edit/${record.id}`} className="">
                            <i className="fa fa-pen"></i>
                          </Link>
                          <Link to={`delete/${record.id}`} className="">
                            <i className="fa fa-trash"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>Date is in DD-MM-YYYY format</p>
              </div>
            </>
          )}
        </>
      }
    </div>
  );
};

export default Purchase;
