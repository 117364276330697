/** @format */

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../../../common/LoadingSpinner";
import AuthContext from "../../../context/AuthContext";

const SaleDetail = () => {
  const params = useParams();
  const id = params.id;

  const context = useContext(AuthContext);
  const [apiData, setApiData] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_ROOT}/v1/user/sale/${id}`;
        const response = await axios.get(apiUrl, {
          headers: {
            token: localStorage.getItem("token"),
            Accept: "application/json",
          },
        });

        setApiData({ data: response.data.record });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response.status === 401) {
          context.logout();
        }
        context.notify({ type: 2, msg: error.response.data.error });
      }
    };

    loadData();

    return () => {};
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <h3>Your Sale Details</h3>
      {apiData && (
        <>
          <div>
            <Link
              to={`/user/sale/edit/${apiData.data.id}`}
              className="btn btn-success"
            >
              Edit
            </Link>
            <Link
              to={`/user/sale/delete/${apiData.data.id}`}
              className="btn btn-danger"
            >
              Delete
            </Link>
          </div>
          <div className="responsive py-3">
            <div className="row table">
              <div className="col-sm-12 ">
                <label>Date</label>
                {apiData.data.created_at}
              </div>
              <div className="col-sm-4">
                <label>Invoice</label>
                {apiData.data.sale_detail.invoice}
              </div>
              <div className="col-sm-4">
                <div>
                  <label>Product</label>
                  {apiData.data.product.name}
                </div>
                <div>
                  <label>Product Code</label>
                  {apiData.data.product.product_code}
                </div>
              </div>
              <div className="col-sm-4 text-justify-end">
                <div>
                  <label>Total</label>&#8377;
                  {parseFloat(apiData.data.total_cost).toFixed("2")}
                </div>
                <div>
                  <label>Others</label>&#8377;
                  {parseFloat(apiData.data.other_expense).toFixed("2")}
                </div>
                <small>
                  <label>Received</label>&#8377;
                  {parseFloat(apiData.data.paid).toFixed("2")}
                </small>

                <small>
                  <label>Balance</label>&#8377;
                  {parseFloat(apiData.data.balance).toFixed("2")}
                </small>
              </div>
              <div className="col-sm-4">
                <label>Quantity</label>
                {apiData.data.quantity}
              </div>
              <div className="col-sm-4">
                <label>Rate</label>
                {apiData.data.rate}
              </div>
              <div className="col-sm-4">
                <label>Unit</label>
                {apiData.data.unit}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SaleDetail;
