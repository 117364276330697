/** @format */

import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../../NotFound";
import Inventory from "./Invenory";
import ProfitLoss from "./ProfitLoss";
import Purchase from "./Purchase";
import ReportDashboard from "./ReportDashboard";
import Sale from "./Sale";

const Report = () => {
  // Auth Check
  useEffect(() => {}, []);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title text-center">Report Dashboard</h2>
          <Routes>
            <Route path="" element={<ReportDashboard />}>
              <Route path="inventory" element={<Inventory />} />
              <Route path="purchase" element={<Purchase />} />
              <Route path="sale" element={<Sale />} />
              <Route path="profit-loss" element={<ProfitLoss />} />
              {/* <Route index element={<SaleList />} />
							<Route
								path='/detail/:id'
								element={<SaleDetail />}
							/>
							<Route path='/add' element={<AddRecord />} />
							<Route path='/edit/:id' element={<EditRecord />} />
							<Route
								path='delete/:id'
								element={<DeleteRecord />}
							/>
							<Route
								path='delete/invoice/:id'
								element={<DeleteInvoice model='invoice' />}
							/> */}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Report;
