/** @format */

import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, NavLink, useNavigate } from "react-router-dom";
import User from "./User";
import Company from "./Company/Company";
// import AuthCheck from "../../common/AuthCheck";
import AuthContext from "../../context/AuthContext";
import useAuthCheck from "../../Hooks/useAuthCheck";
// import useAuthCheck from "../../Hooks/useAuthCheck";
// import AddCompany from "./Company/AddCompany";
// import ListCompany from "./Company/ListCompany";

const apiRoot = process.env.REACT_APP_API_ROOT;

const AdminDashboard = () => {
	const navigate = useNavigate();
	const authCtx = useContext(AuthContext);

	const authError = useAuthCheck(5);
	// Check user auth
	useEffect(() => {
		if (!authError) {
			loadPage();
		} else {
			// if (authError.error) {
			// 	// Handle auth error
			authCtx.notify({ type: 2, msg: authError.errMessage });
			navigate("/");
			// }
		}

		// }

		window.scrollTo(0, 0);
	}, []);

	const [apiData, setApiData] = useState({});

	const loadPage = async () => {
		const submitUrl = apiRoot + "/v1/admin";
		// console.log(submitUrl);
		try {
			const res = await fetch(submitUrl, {
				method: "GET",
				// credentials: "include",
				headers: {
					token: localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
			});

			const data = await res.json();
			if (res.status === 200) {
				setApiData(data);
			} else {
				setApiData({ msg: "Error in processing." });
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className='container'>
			<h1>Admin Dashboard</h1>
			{/* <Outlet /> */}
			<ul className='horiz-menu'>
				<li className=''>
					<NavLink
						className={(isActive) =>
							"nav-link" + (!isActive ? " unselected" : "")
						}
						to='user'>
						Users
					</NavLink>
				</li>
				<li>
					<NavLink
						className={(isActive) =>
							"nav-link" + (!isActive ? " unselected" : "")
						}
						to='company'>
						Company
					</NavLink>
				</li>
				<li>
					<NavLink
						className={(isActive) =>
							"nav-link" + (!isActive ? " unselected" : "")
						}
						to='company'>
						Configuration
					</NavLink>
				</li>
				<li>
					<NavLink
						className={(isActive) =>
							"nav-link" + (!isActive ? " unselected" : "")
						}
						to='company'>
						Add Company
					</NavLink>
				</li>
			</ul>
			<Routes>
				<Route path='/' element={<User />} />
				<Route path='user' element={<User />} />
				<Route path='/company/*' element={<Company />} />
				{/* <Route path='product' element={<Product />} /> */}
			</Routes>
		</div>
	);
};

export default AdminDashboard;
