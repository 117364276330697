/** @format */

import React from "react";
import { NavLink, Link } from "react-router-dom";

const Footer = () => {
	return (
		<div>
			<footer id='footer' className='mt-5 pb-3 text-center'>
				<div className='container'>
					<div>
						<ul className='horizontal'>
							<li>
								<NavLink to='/'>Home</NavLink>
							</li>
							<li>
								<NavLink to='/about'>About</NavLink>
							</li>
							<li>
								<NavLink to='/features'>Features</NavLink>
							</li>
							<li>
								<NavLink to='/contact'>Contact</NavLink>
							</li>
						</ul>
					</div>
				</div>
				<div className='container'>
					<div>
						<span className=''>
							Designed and Developed by{" "}
							<a
								href='http://seontechnologies.in'
								target='_blank'
								className='seon'>
								Seon Technologies
							</a>
						</span>
						<span>
							&nbsp;&copy; Copyright{" "}
							<strong>Vyapar Sahayak</strong>
						</span>
					</div>
				</div>
			</footer>
			{/* <a
				target='_blank'
				className='whatsapp'
				href='https://api.whatsapp.com/send?phone=+919050231702'
				title='How can we help'>
				<i className='fab fa-whatsapp fa-3x'></i>
			</a> */}
		</div>
	);
};

export default Footer;
