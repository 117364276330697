/** @format */

import React, { useContext, useEffect } from "react";
import { useNavigate, Route, Routes, NavLink } from "react-router-dom";
import AuthCheck from "../../common/AuthCheck";
import AuthContext from "../../context/AuthContext";
import NotFound from "../../NotFound";
// import useAuthCheck from "../../Hooks/useAuthCheck";
import DashboardHome from "./DashboardHome";
import Master from "./Configure/Master";
import Purchase from "./Purchase/Purchase";
import Report from "./Report/Report";
import Sale from "./Sale/Sale";

const UserDashboard = () => {
	const navigate = useNavigate();
	const context = useContext(AuthContext);

	// useAuthCheck(3);
	// Check Auth and Autorization

	// const authError = useAuthCheck(3);
	const authError = AuthCheck(3);
	// const authError = false;
	useEffect(() => {
		window.scrollTo(0, 0);
		if (!authError) {
			if (context.user.role === 5) {
				navigate("/admin");
			}
		} else {
			if (authError.error) {
				context.notify({ type: 2, msg: authError.errMessage });
				// Handle auth error
				context.logout();
				navigate("/", { state: "Auth Error" });
				// window.location.href = "/";
			}
		}
	});
	// console.log("User dashboard");
	return (
		<div className='container'>
			<>
				<div>
					<span>
						<strong className='card-title'>
							Welcome {context.user && context.user.fname}!
						</strong>
					</span>
					<span>
						&nbsp;You are logged in as{" "}
						{context.user && context.user.email}
					</span>
				</div>
				<ul className='horiz-menu'>
					<li className=''>
						<NavLink
							className={(isActive) =>
								"nav-link" + (!isActive ? " unselected" : "")
							}
							to='purchase'>
							Purchase
						</NavLink>
					</li>
					<li>
						<NavLink
							className={(isActive) =>
								"nav-link" + (!isActive ? " unselected" : "")
							}
							to='sale'>
							Sale
						</NavLink>
					</li>
					<li>
						<NavLink
							className={(isActive) =>
								"nav-link" + (!isActive ? " unselected" : "")
							}
							to='report'>
							Report
						</NavLink>
					</li>
					<li>
						<NavLink
							className={(isActive) =>
								"nav-link" + (!isActive ? " unselected" : "")
							}
							to='configure'>
							Configure
						</NavLink>
					</li>
				</ul>
			</>
			<Routes>
				{/* <Route path='/company/*' element={<Company />} /> */}

				<Route index element={<DashboardHome />} />
				<Route path='purchase/*' element={<Purchase />} />
				<Route path='sale/*' element={<Sale />} />
				<Route path='report/*' element={<Report />} />
				<Route path='configure/*' element={<Master />} />
				<Route path='*' element={<NotFound />} />
				{/* <Route path='pricing/:slug' element={<Pricing />}></Route> */}
			</Routes>
		</div>
	);
};

export default UserDashboard;
