/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../../../context/AuthContext";
// import AuthCheck from "../../../Hooks/AuthCheck";
import useAuthCheck from "../../../Hooks/useAuthCheck";
import useForm from "../../../Hooks/useForm";
import CompanyValidation from "./Form/CompanyValidation";

const apiRoot = process.env.REACT_APP_API_ROOT;
const AddCompany = () => {
	let navigate = useNavigate();

	// Auth check
	const authError = useAuthCheck(5);

	useEffect(() => {
		if (authError) {
			navigate("/", {
				state: authError.errMessage,
			});
		}
	}, []);
	/* Code below check with return value
	let authCheck = true;
	useEffect(() => {
		authCheck = AuthCheck(5);
		if (!authCheck) {
			navigate("/", {
				state: "You are not authorized.",
			});
		}
		console.log(authCheck);
	}, []);
	*/

	const callbackForm = () => {
		submitForm();
	};

	const { values, errors, handleChange, handleSubmit } = useForm(
		callbackForm,
		CompanyValidation
	);

	// Context Value
	// const value = useContext(AuthContext);

	const submitForm = async () => {
		const submitUrl = apiRoot + "/v1/admin/company";
		try {
			const res = await fetch(submitUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					token: localStorage.getItem("token"),
				},
				body: JSON.stringify(values),
			});

			if (res.status === 200) {
				const data = await res.json();
				navigate("/admin/company", {
					state: data.message,
				});
			} else {
				setApiData({ msg: "Error in processing." });
			}
		} catch (err) {
			console.log(err);
		}
	};

	const [apiData, setApiData] = useState({});
	return (
		<div>
			<h2 className='text-center'>Add new Company</h2>
			{apiData.msg && <p>{apiData.msg}</p>}
			<div className='mt-0 py-0 container text-center'>
				<form className='' method='post' onSubmit={handleSubmit}>
					<input
						className={`${errors.name && "error"}`}
						type='text'
						name='name'
						placeholder='Company Name'
						value={values.name || ""}
						onChange={handleChange}
					/>
					<span className='error'>
						{errors.name && <>{errors.name}</>}
					</span>

					<input
						className={`${errors.email && "error"}`}
						type='text'
						name='email'
						placeholder='Admin Email'
						value={values.email || ""}
						onChange={handleChange}
					/>
					<span className='error'>
						{errors.email && <>{errors.email}</>}
					</span>
					<input
						className={`${errors.phone && "error"}`}
						type='text'
						name='phone'
						placeholder='Phone Number'
						value={values.phone || ""}
						onChange={handleChange}
					/>
					<span className='error'>
						{errors.phone && <>{errors.phone}</>}
					</span>

					<input
						className={`${errors.fname && "error"}`}
						type='text'
						name='fname'
						placeholder='First name'
						value={values.fname || ""}
						onChange={handleChange}
					/>
					<span className='error'>
						{errors.fname && <>{errors.fname}</>}
					</span>

					<input
						className={`${errors.lname && "error"}`}
						type='text'
						name='lname'
						placeholder='Last name'
						value={values.lname || ""}
						onChange={handleChange}
					/>
					<span className='error'>
						{errors.lname && <>{errors.lname}</>}
					</span>

					{/* dsn, password should be automatically populated */}
					<input
						className='btn btn-lg fw-bold '
						type='submit'
						value='Save'
					/>
				</form>
			</div>
		</div>
	);
};

export default AddCompany;
