/** @format */

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

function useAuthCheck(role) {
	const navigate = useNavigate();

	let authError = false;
	const { user, logout } = useContext(AuthContext);

	// console.log("Neeraj :- " + user);
	// Check authentication
	if (!user) {
		logout();
		// console.log("Not User");
		authError = {
			error: true,
			isAuthorized: false,
			errMessage: "Please login to access this resource.",
		};
		// navigate("/", { state: "Auth Error" });
		return authError;
	} else if (role != undefined) {
		// console.log(role + " role >>> user role " + user.role);
		if (role > user.role) {
			authError = {
				error: true,
				isAuthorized: false,
				errMessage: "You are not authorized to access this resource.",
			};

			// navigate("/", {
			// 	state: "You are not authorized to access this resource",
			// });
			return authError;
			// window.location.reload();
			// return;
			// navigate("/", {
			// 	state: "You are not authorized to access this resource.",
			// });
			// validUser = false;
		}
	}

	// Check authorization

	return authError;

	// console.log("I am in UseAuthCheck");
	// useEffect(() => {
	// 	if (!user) {
	// 		authStatus = false;
	// 		navigate("/", {
	// 			state: "Auth error",
	// 		});
	// 	} else if (userRole != undefined) {
	// 		// Check authorization
	// 		if (userRole !== user.role) {
	// 			// console.log(userRole + " > " + user.role);
	// 			authStatus = false;
	// 			navigate("/", {
	// 				state: "You are not authorized to access this resource pl check.",
	// 			});
	// 		}
	// 	}
	// });

	// return authStatus;

	// return () => controller.abort();
	// return () => {
	// 	if (!authStatus) {
	// 		controller.abort();
	// 	}
	// };
	// return authStatus;
}

export default useAuthCheck;
