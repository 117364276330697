/** @format */

import { NavLink, Outlet } from "react-router-dom";

const MasterDashboard = () => {
	return (
		<>
			<p>Configure System and Master data.</p>
			<>
				<ul className='horiz-menu'>
					<li>
						<NavLink to='product' className='btn fw-bold'>
							Product
						</NavLink>
					</li>
					<li>
						<NavLink to='' className='btn fw-bold'>
							User
						</NavLink>
					</li>
				</ul>
			</>
			<Outlet />
		</>
	);
};

export default MasterDashboard;
