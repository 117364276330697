/** @format */

import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";

const AuthContext = createContext();

export default AuthContext;

const apiRoot = process.env.REACT_APP_API_ROOT;
export const AuthProvider = ({ children }) => {
	let navigate = useNavigate();

	const [authToken, setAuthToken] = useState(() =>
		window.localStorage.getItem("token")
			? window.localStorage.getItem("token")
			: null
	);

	const [user, setUser] = useState(() =>
		window.localStorage.getItem("user")
			? JSON.parse(window.localStorage.getItem("user"))
			: false
	);

	let token = window.localStorage.getItem("token")
		? window.localStorage.getItem("token")
		: null;
	let refreshToken = window.localStorage.getItem("refreshToken")
		? window.localStorage.getItem("refreshToken")
		: null;

	// Get User if user refreshes page.
	const fetchUser = async () => {
		const submitUrl = apiRoot + "/v1/user";
		try {
			const res = await fetch(submitUrl, {
				method: "GET",
				headers: {
					token: token,
					"Content-Type": "application/json",
				},
			});
			if (res.status === 200) {
				const data = await res.json();
				setUser(data);
			} else {
				setUser(false);
				window.localStorage.removeItem("token");
				window.localStorage.removeItem("refreshToken");
				window.localStorage.removeItem("user");
			}
		} catch (err) {
			console.log("Error " + err);
		}
	};

	// Get new refresh Token
	const updateToken = async () => {
		const submitUrl = apiRoot + "/v1/user/refreshtoken";
		try {
			const res = await fetch(submitUrl, {
				method: "GET",
				headers: {
					token: localStorage.getItem("refreshToken"),
					"Content-Type": "application/json",
				},
			});

			if (res.status === 200) {
				const data = await res.json();
				window.localStorage.setItem("token", data.token);
				window.localStorage.setItem("refreshToken", data.refreshToken);
			} else {
				window.localStorage.removeItem("token");
				window.localStorage.removeItem("refreshToken");
				window.localStorage.removeItem("user");
				setUser(false);
				navigate("/");
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		let intervalMinutes = process.env.REACT_APP_TOKEN_REFRESH_DURATION;
		// let intervalMinutes = 1000 * 60 * 4; //4 Minutes
		// console.log("Refresh token in effect: " + refreshToken);
		const refreshToken = window.localStorage.getItem("refreshToken")
			? window.localStorage.getItem("refreshToken")
			: null;

		let interval = setInterval(() => {
			if (refreshToken != null) {
				// console.log("Will Refresh token : " + refreshToken);
				updateToken();
			}
		}, intervalMinutes);
		return () => clearInterval(interval);
	}, [refreshToken]);

	// Handle user logout
	const logout = () => {
		setUser(false);

		window.localStorage.removeItem("token");
		window.localStorage.removeItem("refreshToken");
		window.localStorage.removeItem("user");

		navigate("/");
	};

	// Set user data in state after login
	const setLoginData = (data) => {
		if (data != null) {
			setUser(data.user);
			setAuthToken(data.token);

			window.localStorage.setItem("token", data.token);
			window.localStorage.setItem("refreshToken", data.refreshToken);
			window.localStorage.setItem("user", JSON.stringify(data.user));
		}
	};

	// Function to handle notify service
	const notify = ({ type, msg }) => {
		const opt = {
			duration: 6000,
			// style: {
			// 	borderRadius: "10px",
			// 	background: "#ccc",
			// 	color: "#fff",
			// },
		};
		if (type === "1") {
			//Success
			toast(msg, opt);
		} else if (type === 2) {
			// Error
			toast.error(msg, opt);
		} else {
			//	Info
			toast(msg, opt);
		}
	};

	const contextData = {
		user: user,
		fetchUser: fetchUser,
		authToken: authToken,
		notify: notify,
		logout: logout,
		setLoginData: setLoginData,
	};

	return (
		<AuthContext.Provider value={contextData}>
			{/* {loading ? null : children} */}
			{children}
		</AuthContext.Provider>
	);
};
