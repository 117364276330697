/** @format */

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
// import { useNavigate } from "react-router-dom";

function AuthCheck(role) {
	// const navigate = useNavigate();

	let authError = false;
	const { user, logout } = useContext(AuthContext);

	// console.log("Neeraj :- " + user);
	// Check authentication
	if (!user) {
		logout();
		// console.log("Not User");
		authError = {
			error: true,
			isAuthorized: false,
			errMessage: "Please login to access this resource.",
		};
		// navigate("/", { state: "Auth Error" });
		return authError;
	} else if (role != undefined) {
		// console.log(role + " role >>> user role " + user.role);
		if (role > user.role) {
			authError = {
				error: true,
				isAuthorized: false,
				errMessage: "You are not authorized to access this resource.",
			};

			// console.log("ROle check");
			// notify({ type: 2, msg: authError.errMessage });

			// window.location.href = "/";
			// navigate("/", { state: "Auth Error" });
			return authError;
		}
	}

	// Check authorization

	return authError;
}

export default AuthCheck;
