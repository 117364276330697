/** @format */

import Navbar from "./Navbar";

const Header = () => {
	return (
		<div className='container'>
			<Navbar />
		</div>
	);
};

export default Header;
