/** @format */

import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
// import AuthContext from "../../../context/AuthContext";
// import useAuthCheck from "../../../Hooks/useAuthCheck";
import AddRecord from "./Form/AddRecord";
import PurchaseDetail from "./PurchaseDetail";
import PurchaseList from "./PurchaseList";
import DeleteRecord from "./DeleteRecord";

import EditRecord from "./Form/EditRecord";
import AuthCheck from "../../../common/AuthCheck";

const Purchase = () => {
	// Auth Check
	// const context = useContext(AuthContext);
	const navigate = useNavigate();
	const authError = AuthCheck(3);

	useEffect(() => {
		if (authError) {
			navigate("/");
		}
		// const controller = new AbortController();
		// if (authError) {
		// 	context.notify({ type: 2, msg: authError.errMessage });
		// 	navigate("/");
		// }
		// return () => controller.abort();
	}, []);
	// console.log("Purchase");
	return (
		<>
			<div className='card'>
				<div className='card-body'>
					<h2 className='card-title text-center'>
						Purchase Dashboard
					</h2>
					<Routes>
						<Route index element={<PurchaseList />} />
						<Route path='add' element={<AddRecord />} />
						<Route path='detail/:id' element={<PurchaseDetail />} />
						<Route path='edit/:id' element={<EditRecord />} />
						<Route path='delete/:id' element={<DeleteRecord />} />
						{/* <Route path='/:id' element={<CompanyDetail />} /> */}
						{/* <Route path='add' element={<AddCompany />} />
				<Route path='edit/:id' element={<EditCompany />} />
				<Route path='delete/:id' element={<DeleteCompany />} /> */}
					</Routes>
				</div>
			</div>
		</>
	);
};

export default Purchase;
