/** @format */

import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthCheck from "../../../common/AuthCheck";
import AuthContext from "../../../context/AuthContext";

const DeleteRecord = () => {
	// Auth Check
	const context = useContext(AuthContext);
	const navigate = useNavigate();
	const params = useParams();

	const id = params.id;
	// Do API call to delete record
	const delRec = async () => {
		try {
			const apiUrl = `${process.env.REACT_APP_API_ROOT}/v1/user/purchase/${id}`;
			const response = await axios.delete(apiUrl, {
				headers: {
					Accept: "application/json",
					token: localStorage.getItem("token"),
				},
			});

			if (response.status === 200) {
				context.notify({ type: 1, msg: response.data.message });

				navigate("/user/purchase");
			}
		} catch (error) {
			if (error.response.status === 401) {
				context.logout();
			}
			context.notify({ type: 2, msg: error.response.data.error });
			navigate("/user/purchase");
		}
	};

	// Auth Check
	const authError = AuthCheck(3);
	useEffect(() => {
		if (authError) {
			navigate("/");
		}
	}, []);

	const handleDelete = () => {
		delRec();
	};
	return (
		<>
			<div className='text-center py-4'>
				<h5>You are about to delete the record. Are you sure?</h5>
				<button
					className='btn btn-success'
					onClick={() => {
						navigate("/user/purchase");
					}}>
					Cancel
				</button>
				<button className='btn btn-danger' onClick={handleDelete}>
					Delete
				</button>
			</div>
		</>
	);
};

export default DeleteRecord;
