export default function validate(values) {
	let errors = {};
	if (!values.name) {
		errors.name = "Company is required";
	}
	if (!values.phone) {
		errors.phone = "Phone is required.";
	}

	if (!values.fname) {
		errors.fname = "First name is required.";
	}

	if (!values.email) {
		errors.email = "Email is required.";
	}
	if (!values.lname) {
		errors.lname = "Last name is required.";
	}

	return errors;
}
