/** @format */

import React from "react";

const LoadingSpinner = () => {
	// console.log("in Spinner");
	return (
		<div className='text-center'>
			<h2 className='loading-spinner'>Loading...</h2>
			<div
				className='spinner-grow'
				style={{ width: "5rem", height: "5rem" }}
				role='status'>
				<span className='sr-only'>Loading...</span>
			</div>
		</div>
	);
};

export default LoadingSpinner;
