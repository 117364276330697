/** @format */

import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AuthCheck from "../../../common/AuthCheck";
import SaleDetail from "./SaleDetail";
import SaleDashboard from "./SaleDashboard";
import SaleList from "./SaleList";
import NotFound from "../../../NotFound";
import AddRecord from "./form/AddRecord";
import EditRecord from "./form/EditRecord";
import DeleteRecord, { DeleteInvoice } from "./DeleteRecord";

const Sale = () => {
	// Auth Check
	const navigate = useNavigate();
	const authError = AuthCheck(3);

	useEffect(() => {
		if (authError) {
			navigate("/");
		}
	}, []);

	return (
		<>
			<div className='card'>
				<div className='card-body'>
					<h2 className='card-title text-center'>Sale Dashboard</h2>
					<Routes>
						<Route path='' element={<SaleDashboard />}>
							<Route index element={<SaleList />} />
							<Route
								path='/detail/:id'
								element={<SaleDetail />}
							/>
							<Route path='/add' element={<AddRecord />} />
							<Route path='/edit/:id' element={<EditRecord />} />
							<Route
								path='delete/:id'
								element={<DeleteRecord />}
							/>
							<Route
								path='delete/invoice/:id'
								element={<DeleteInvoice model='invoice' />}
							/>
						</Route>
						<Route path='*' element={<NotFound />} />
					</Routes>
				</div>
			</div>
		</>
	);
};

export default Sale;
