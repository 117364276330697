/** @format */

import { NavLink, Outlet } from "react-router-dom";

const ReportDashboard = () => {
  return (
    <>
      <p>Generate and Download Various Reports.</p>
      <>
        <ul className="horiz-menu">
          <li>
            <NavLink to="inventory" className="btn fw-bold">
              Inventory
            </NavLink>
          </li>
          <li>
            <NavLink to="purchase" className="btn fw-bold">
              Purchase
            </NavLink>
          </li>
          <li>
            <NavLink to="sale" className="btn fw-bold">
              Sales
            </NavLink>
          </li>
          <li>
            <NavLink to="profit-loss" className="btn fw-bold">
              Profit/Loss
            </NavLink>
          </li>
        </ul>
      </>
      <Outlet />
    </>
  );
};

export default ReportDashboard;
