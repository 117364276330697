/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";

import axios from "axios";
import AuthCheck from "../../../../common/AuthCheck";
import Select from "react-select";

const apiRoot = process.env.REACT_APP_API_ROOT;
const AddRecord = () => {
  const context = useContext(AuthContext);
  // Auth check
  const authError = AuthCheck(3);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (authError) {
      navigate("/");
    }
    //   Load product
    const loadProducts = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_ROOT}/v1/user/product/list/`;
        const response = await axios.get(apiUrl, {
          headers: {
            token: localStorage.getItem("token"),
            Accept: "application/json",
          },
        });

        setProducts(response.data.records);
        // return (products = response.data.records);
        //   console.log("OKKKK", products);
      } catch (error) {
        if (error.response.status === 401) {
          context.logout();
        }
      }
    };

    loadProducts();
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  // const onSubmit = (data) => console.log(data);

  const submitForm = async (data) => {
    const submitUrl = apiRoot + `/v1/user/purchase/`;
    try {
      const response = await axios.post(submitUrl, data, {
        headers: {
          token: localStorage.getItem("token"),
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        context.notify({ type: 1, msg: response.data.message });

        navigate("/user/purchase");
      }
    } catch (error) {
      if (error.response.status === 401) {
        context.logout();
      }
      context.notify({ type: 2, msg: error.response.data.error });
    }
  };

  // Update total cost as per price and quantity
  const [totalCost, setTotalCost] = useState({
    itemCost: 0,
    otherExpense: 0,
  });

  const update_total = (e) => {
    let qty = parseFloat(document.querySelector("#quantity").value);
    let price = parseFloat(document.querySelector("#price").value);
    let otherExpense = parseFloat(
      document.querySelector("#other-expense").value
    );

    qty = isNaN(qty) ? 0 : qty;
    price = isNaN(price) ? 0 : price;
    otherExpense = isNaN(otherExpense) ? 0 : otherExpense;

    let itemCost = qty * price;
    let totalCost = itemCost;
    if (otherExpense > 0) {
      totalCost += otherExpense;
    }
    setTotalCost({
      itemCost: itemCost.toFixed(2),
      otherExpense: otherExpense.toFixed(2),
      totalCost: totalCost.toFixed(2),
    });
  };

  // Calculate balance due
  const [dueBalance, setDueBalance] = useState(0);
  const update_balance = () => {
    let paid = parseFloat(document.querySelector("#paid").value);
    let balance = (totalCost.totalCost - paid).toFixed(2).toString();
    setDueBalance(balance);

    setValue("due", balance);
  };

  return (
    <div>
      <h2 className="text-center">Add New Record</h2>

      <div className="mt-0 py-0 container text-center">
        <form className="" method="post" onSubmit={handleSubmit(submitForm)}>
          <div className="form-group">
            <input className="btn btn-success " type="submit" value="Save" />

            <Link to="/user/purchase" className="btn btn-danger">
              Cancel
            </Link>
          </div>
          <div className="form-group">
            <label>Purchase Order No / Bill No</label>
            <input
              type="text"
              className={`${errors.po_number && "error"}`}
              placeholder="Purchase Order No / Bill No"
              defaultValue={""}
              {...register("po_number", {
                required: "Purchase Order Number/Bill is required",
              })}
            />
            {errors.po_number && (
              <span className="error">{errors.po_number.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>Product</label>
            <Controller
              control={control}
              name="product_id"
              placeholder="Product"
              //   defaultValue={apiData.product_id}
              className={`${errors.product_id && "error"}`}
              rules={{
                required: "Product is required.",
              }}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Select
                  inputRef={ref}
                  options={products}
                  //   onBlur={onBlur}
                  selected={value}
                  // isOptionSelected={apiData.sale_detail.product_id}
                  value={products.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                />
              )}
            />
            {errors.product_id && (
              <span className="error">{errors.product_id.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>Item Quantity</label>
            <input
              type="text"
              id="quantity"
              className={`${errors.quantity && "error"}`}
              placeholder="Quantity"
              defaultValue={""}
              autoComplete="Off"
              {...register("quantity", {
                onChange: (e) => update_total(e),
                required: "Purchase quantity is required",
                pattern: {
                  value: /[0-9]+$/,
                  message: "Please enter number only",
                },
                validate: {
                  positive: (v) =>
                    parseInt(v) > 0 || "Quantity must be greater than 0.",
                },
              })}
            />
            {errors.quantity && (
              <span className="error">{errors.quantity.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>Unit Rate</label>
            <input
              type="text"
              id="price"
              className={`${errors.rate && "error"}`}
              placeholder="Unit Rate"
              defaultValue={""}
              autoComplete="Off"
              // onBlurCapture={update_total}
              {...register("rate", {
                onChange: (e) => update_total(e),
                required: "Unit price is required",
                pattern: {
                  value: /[0-9]+$/,
                  message: "Please enter number only",
                },
                /*validate: {
								positive: (v) =>
									parseInt(v) > 0 ||
									"Should be greater than 0.",

								// lessThanTen: (v) =>
								// 	parseInt(v) < 10 ||
								// 	"should be lower than 10",

								// you can do asynchronous validation as well
								// checkUrl: async () =>
								// 	(await fetch()) || "error message", // JS only: <p>error message</p> TS only support string

								// messages: (v) => !v && ["test", "test2"],
							},*/
              })}
            />
            {errors.rate && (
              <span className="error">{errors.rate.message}</span>
            )}
          </div>
          <div
            className="total-cost-hld"
            // style={{
            // 	background: "purple",
            // 	color: "white",
            // 	fontWeight: "bold",
            // }}
          >
            <p>
              Total Cost: Total Cost: <i className="fas fa-rupee-sign"></i>{" "}
              {totalCost.itemCost}
            </p>
            {totalCost.otherExpense > 0 && (
              <p>
                Other Expense: <i className="fas fa-rupee-sign"></i>{" "}
                {totalCost.otherExpense}
              </p>
            )}
            {totalCost.totalCost && (
              <p>
                Total Cost: <i className="fas fa-rupee-sign"></i>{" "}
                {totalCost.totalCost}
              </p>
            )}
          </div>
          {/* <select
						className={`${errors.price && "error"}`}
						{...register("unit", {
							required: "Unit is required",
							message: "",
						})}>
						<option value=''>Select Unit</option>
						<option value='kg'>Kg</option>
						<option value='piece'>Piece</option>
						<option value='box'>Box</option>
					</select> */}
          <div className="form-group py-3">
            <label className="form-label">Item Unit</label>
            <div className="form-check form-check-inline">
              <input
                id="kg"
                type="radio"
                className={`${errors.unit && "error"} form-check-input`}
                placeholder="Unit"
                defaultValue="kg"
                {...register("unit", {
                  required: "Unit is required",
                  message: "",
                })}
              />
              <label className="form-check-label" htmlFor="kg">
                KG
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                id="piece"
                type="radio"
                className={`${errors.unit && "error"} form-check-input`}
                placeholder="Unit"
                defaultValue="piece"
                {...register("unit", {
                  required: "Unit is required",
                  message: "",
                })}
              />
              <label className="form-check-label" htmlFor="piece">
                Piece
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                id="box"
                type="radio"
                className={`${errors.unit && "error"} form-check-input`}
                placeholder="Unit"
                defaultValue="box"
                {...register("unit", {
                  required: "Unit is required",
                  message: "",
                })}
              />
              <label className="form-check-label" htmlFor="box">
                Box
              </label>
            </div>

            {errors.unit && (
              <span className="error">{errors.unit.message}</span>
            )}
          </div>
          <div className="form-group">
            <small>
              Other expense like transport, installation, fitting. Provide 0 if
              there is no other expense.
            </small>
            <label>Other Expense</label>
            <input
              id="other-expense"
              type="text"
              className={`${errors.other_expense && "error"}`}
              placeholder="Other Expense"
              defaultValue="0"
              {...register("other_expense", {
                onChange: (e) => update_total(),
                pattern: {
                  value: /[0-9]+$/,
                  message: "Please enter number only",
                },
              })}
            />
            {errors.other_expense && (
              <span className="error">{errors.other_expense.message}</span>
            )}
          </div>
          {/* <div className='py-3'>
						<input
							id='total_cost'
							type='hidden'
							value={totalCost}
							{...register("total_cost")}
						/>
					</div> */}
          <div className="form-group">
            <label>Amount Paid</label>
            <input
              type="text"
              id="paid"
              className={`${errors.paid && "error"}`}
              placeholder="Amount Paid"
              defaultValue=""
              autoComplete="off"
              {...register("paid", {
                onChange: (e) => update_balance(e),
                required: "Paid amout is required.",
                pattern: {
                  value: /[0-9]+$/,
                  message: "Please enter number only",
                },
              })}
            />
            {errors.paid && (
              <span className="error">{errors.paid.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>Balance Due</label>
            <input
              type="text"
              id="balance"
              className={`${errors.due && "error"}`}
              placeholder="Balance Due"
              defaultValue=""
              readOnly
              {...register("due", {
                value: dueBalance ? dueBalance : "0",
                pattern: {
                  value: /[0-9]+$/,
                  message: "Please enter number only",
                },
              })}
            />
            <span>{errors.due?.message}</span>

            {errors.due && <span className="error">{errors.due.message}</span>}
          </div>
          <div className="form-group">
            <input className="btn btn-success " type="submit" value="Save" />
            &nbsp;
            <Link to="/user/purchase" className="btn btn-danger">
              Cancel
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddRecord;
