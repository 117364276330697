import axios from "axios";
import Moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadingSpinner from "../../../common/LoadingSpinner";
import Pagination from "../../../common/Pagination";
import AuthContext from "../../../context/AuthContext";

const apiRoot = process.env.REACT_APP_API_ROOT;

const ProductList = () => {
  const context = useContext(AuthContext);

  // Auth check
  // const navigate = useNavigate();
  // const authError = useAuthCheck(3);

  const location = useLocation();

  const [apiData, setApiData] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(true);

  // Company ID
  const id = context.user.company;

  const loadPage = async (pageNo) => {
    const submitUrl = apiRoot + `/v1/user/product/?page=${pageNo}`;

    try {
      const res = await axios.get(submitUrl, {
        headers: {
          token: localStorage.getItem("token"),
          Accept: "application/json",
        },
      });

      setApiData({ data: res.data.records });
      setTotalPage(res.data.total_pages);
      setLoading(false);
    } catch (err) {
      setApiData(false);
      setLoading(false);
      if (err.response.status === 401) {
        context.logout();
      }

      context.notify({ type: 2, msg: err.response.data.error });
    }
  };
  // }
  useEffect(() => {
    // if (authError) {
    // 	navigate("/");
    // }

    if (context.user) {
      if (context.user.company) {
        loadPage(1);
      }
    }
  }, [id]);

  const handlePagination = (pageNo) => {
    // console.log(pageNo);
    // console.log(tcurPage.target.dataset.id);

    setLoading(true);
    loadPage(pageNo);
    setCurPage(pageNo);
  };
  // console.log(apiData);
  // if (apiData) {
  // 	apiData.data.map((record) => {
  // 		console.log(record);
  // 	});
  // }

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div className="mb-1">
        <Link className="btn btn-success" to={`${location.pathname}/add`}>
          Add New Product
        </Link>
      </div>

      {!apiData && (
        <>
          <div className="mb-1">
            <h3 className="text-center py-5">No Records found.</h3>
          </div>
        </>
      )}

      <>
        {apiData && (
          <>
            <Pagination
              totalPages={totalPages}
              curPage={curPage}
              handlePagination={handlePagination}
            />
            <div className="row">
              <div className="col-sm-12">
                <div className="table-responsive">
                  <table className="table py-3">
                    <thead>
                      <tr>
                        <th width="100">Id</th>
                        <th>Name</th>
                        <th>Product Code</th>
                        <th>Barcode</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th width="100"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {apiData.data.map((record, index) => (
                        <tr key={index} data-index={index}>
                          <td>{record.id}</td>
                          <td>
                            <Link to={`${record.id}`} className=" ">
                              {record.name}
                            </Link>
                          </td>
                          <td>{record.product_code}</td>
                          <td>{record.barcode}</td>
                          <td>{record.is_active ? "Active" : "Disabled"}</td>
                          <td>
                            {/* {new Intl.DateTimeFormat(
													"en-GB"
												).format(record.created_at)} */}
                            {Moment(record.created_at).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <Link to={`edit/${record.id}`} className="">
                              <i className="fa fa-pen"></i>
                            </Link>
                            <Link to={`delete/${record.id}`} className="">
                              <i className="fa fa-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <p>Date is in DD-MM-YYYY format</p>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default ProductList;
