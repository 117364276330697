/** @format */

import React from "react";
import { Link } from "react-router-dom";

const SaleDetail = ({ order }) => {
  // console.log("Neeraj", order);
  return (
    <div>
      {order && (
        <table className="table table-bordered11 py-3">
          <thead>
            <tr>
              <th width="100">Id</th>
              <th>Product</th>
              <th>Quantity</th>
              <th>Total</th>
              <th>Balance</th>
              <th width="100"></th>
            </tr>
          </thead>
          <tbody>
            {order.map((record, index) => (
              <tr key={index} data-index={index}>
                <td>{record.id}</td>
                <td>
                  <Link to={`detail/${record.id}`}>{record.product.name}</Link>
                </td>
                <td>{record.quantity}</td>
                <td>{record.total_cost}</td>
                <td>{record.balance}</td>
                <td>
                  <Link
                    to={`/user/sale/edit/${record.id}`}
                    className="card-link "
                  >
                    <i className="fa fa-pen"></i>
                  </Link>
                  <Link to={`delete/${record.id}`} className="card-link ">
                    <i className="fa fa-trash"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SaleDetail;
