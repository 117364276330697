import React from "react";

const List = () => {
	return (
		<div>
			<h1>Product List</h1>
		</div>
	);
};

export default List;
