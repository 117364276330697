/** @format */

import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const apiRoot = process.env.REACT_APP_API_ROOT;
const Logout = (props) => {
	const submitUrl = apiRoot + "/v1/logout";
	const navigate = useNavigate();
	const authCtx = useContext(AuthContext);
	const logoutUser = async () => {
		const res = await fetch(submitUrl, {
			method: "GET",
			// credentials: "include",
			headers: {
				token: localStorage.getItem("token"),
				"Content-Type": "application/json",
			},
		});

		if (res.status === 200) {
			// const data = await res.json();
			localStorage.removeItem("token");
			localStorage.removeItem("user");

			authCtx.logout();
			authCtx.notify({
				type: 1,
				msg: "You are successfully logged out.",
			});
			navigate("/");
			// navigate("/");
		} else {
			// setUser({ msg: "Error in processing." });
		}
	};

	useEffect(() => {
		logoutUser();
	}, []);
	return <></>;
};

export default Logout;
