/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";

const Pagination = (props) => {
	// Handle Page click
	const handlePageClick = (e, pageNo) => {
		// console.log(
		// 	"Cur page prop - " +
		// 		pageNo +
		// 		"-" +
		// 		props.curPage +
		// 		" - " +
		// 		props.totalPages
		// );

		if (pageNo == "first") {
			// User is clicking First button
			if (props.curPage > 1) {
				props.handlePagination(1);
			} else {
				e.preventDefault();
			}
		} else if (pageNo == "last") {
			// User is clicking last button
			if (props.curPage < props.totalPages) {
				props.handlePagination(props.totalPages);
			} else {
				e.preventDefault();
			}
		} else if (pageNo == "prev") {
			// User is clicking prev button
			if (props.curPage - 1 > 0) {
				props.handlePagination(props.curPage - 1);
			} else {
				e.preventDefault();
			}
		} else if (pageNo == "next") {
			// User is clicking next button
			if (props.curPage + 1 <= props.totalPages) {
				props.handlePagination(props.curPage + 1);
			} else {
				e.preventDefault();
			}
		} else if (pageNo != props.curPage) {
			// User is clicking page numbers
			props.handlePagination(pageNo);
		} else {
			// props.handlePagination(pageNo);
			e.preventDefault();
		}
	};
	// Create Page links
	const nav_links = (totalPages) => {
		let elementArr = [];
		elementArr.push(
			<li key='0'>
				<Link
					className='btn btn-outline-primary'
					style={{
						background: props.curPage == 1 ? "#ccc" : "",
						color: props.curPage == 1 ? "#000" : "",
					}}
					to='#'
					onClick={(e) => {
						handlePageClick(e, "first");
					}}>
					First
				</Link>
			</li>
		);
		elementArr.push(
			<li key='00'>
				<Link
					className='btn btn-outline-primary'
					style={{
						background: props.curPage == 1 ? "#ccc" : "",
						color: props.curPage == 1 ? "#000" : "",
					}}
					to='#'
					onClick={(e) => {
						handlePageClick(e, "prev");
					}}>
					Prev
				</Link>
			</li>
		);
		for (let i = 1; i <= totalPages; i++) {
			elementArr.push(
				<li key={i}>
					{/* <a
						className='btn btn-outline-primary'
						href='#'
						data-id={i}
						onClick={props.handlePagination}>
						{i}
					</a> */}

					<Link
						className='btn btn-outline-primary '
						style={{
							background: i == props.curPage ? "#000" : "",
							color: i == props.curPage ? "#fff" : "",
						}}
						to='#'
						onClick={(e) => {
							handlePageClick(e, i);
							// props.handlePagination(i);
						}}>
						{i}
					</Link>
				</li>
			);
		}
		elementArr.push(
			<li key={props.totalPages + 1}>
				<Link
					className='btn btn-outline-primary'
					style={{
						background:
							props.curPage == props.totalPages ? "#ccc" : "",
						color: props.curPage == props.totalPages ? "#000" : "",
					}}
					to='#'
					onClick={(e) => {
						handlePageClick(e, "next");
					}}>
					Next
				</Link>
			</li>
		);
		elementArr.push(
			<li key={props.totalPages + 2}>
				<Link
					className='btn btn-outline-primary'
					style={{
						background:
							props.curPage == props.totalPages ? "#ccc" : "",
						color: props.curPage == props.totalPages ? "#000" : "",
					}}
					to='#'
					onClick={(e) => {
						handlePageClick(e, "last");
					}}>
					Last
				</Link>
			</li>
		);
		return elementArr;
	};

	return (
		<>
			{/* <div>{nav_links(5)}</div> */}
			<div className='row table-responsive'>
				<div className='col-sm-4 text-start'>
					Total Pages - {props.totalPages}
				</div>
				<div className='col-md-auto text-end'>
					<ul className='horiz-menu'>
						{nav_links(props.totalPages)}
					</ul>
				</div>
			</div>
		</>
	);
};

export default Pagination;
