/** @format */

import { useState } from "react";
import useForm from "../Hooks/useForm";
import ContactValidationRule from "./validation/ContactValidationRule";
import LoadingSpinner from "../common/LoadingSpinner";
import axios from "axios";

const ContactForm = (props) => {
	const [loading, setLoading] = useState(false);
	const callbackForm = () => {
		submitForm();
	};

	const { values, errors, handleChange, handleSubmit } = useForm(
		callbackForm,
		ContactValidationRule
	);

	const submitForm = async () => {
		const submitUrl = `${process.env.REACT_APP_API_ROOT}/v1/contact`;

		try {
			setLoading(true);

			const res = await axios.post(submitUrl, values);

			if (res.status === 200) {
				setApiData({
					msg: `Thanks for contacting. We have received your query.\nWe will respond soon.`,
				});
				setLoading(false);
				setShowForm(false);
			}
		} catch (err) {
			console.log(err.response.data);
		}
	};

	const [apiData, setApiData] = useState({});
	const [showForm, setShowForm] = useState(true);

	if (loading) {
		return (
			<>
				<h2 className='loading-spinner'>
					Processing... <LoadingSpinner />
				</h2>
			</>
		);
	}
	return (
		<div className=''>
			<div className='mt-0 py-0 text-center'>
				{apiData.msg && (
					<h2 className='fw-bold border-white py-5'>{apiData.msg}</h2>
				)}
				{showForm && (
					<form className='' method='post' onSubmit={handleSubmit}>
						<div className='form-group'>
							<input
								className={`${errors.name && "error"}`}
								type='text'
								name='name'
								placeholder='Your Name'
								value={values.name || ""}
								onChange={handleChange}
							/>
							<span className='error'>
								{errors.name && <>{errors.name}</>}
							</span>
						</div>
						<div className='form-group'>
							<input
								className={`${errors.email && "error"}`}
								type='text'
								name='email'
								placeholder='Email'
								value={values.email || ""}
								onChange={handleChange}
							/>
							<span className='error'>
								{errors.email && <>{errors.email}</>}
							</span>
						</div>
						<div className='form-group'>
							<input
								className={`${errors.mobile && "error"}`}
								type='text'
								name='mobile'
								placeholder='Mobile'
								value={values.mobile || ""}
								onChange={handleChange}
							/>
							<span className='error'>
								{errors.mobile && <>{errors.mobile}</>}
							</span>
						</div>
						<div className='form-group'>
							<input
								className={`${errors.company && "error"}`}
								type='text'
								name='company'
								placeholder='Company'
								value={values.company || ""}
								onChange={handleChange}
							/>
							<span className='error'>
								{errors.company && <>{errors.company}</>}
							</span>
						</div>
						<div className='form-group'>
							<textarea
								className={`${errors.description && "error"}`}
								style={{ width: "100%" }}
								name='description'
								type='text'
								placeholder='How can we help you?'
								value={values.description || ""}
								onChange={handleChange}
							/>
							<span className='error'>
								{errors.description && (
									<>{errors.description}</>
								)}
							</span>
						</div>
						<div className='form-group'>
							<input
								className='btn btn-lg fw-bold btn-success'
								type='submit'
								value='Send'
							/>
						</div>
					</form>
				)}
			</div>
		</div>
	);
};

export default ContactForm;
